import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";
import { Box, Flex, Text } from "@/app/components";

const NewsLetter = () => {
  return (
    <Box color={"black"} pt={10} pb={15} px={[10, 10, 10, 0]}>
      <MaxWidthContainer>
        <Flex alignItems={"center"} flexDirection={"column"}>
          <Text fontSize={4} pb={2}>
            Subscribe to our newsletter
          </Text>
          <Text fontSize={6} pb={4}>
            Promotions, new designs and sales. Directly to your inbox.
          </Text>
          <Flex>
            <TextField id="standard-name" margin="normal" />
            <Button>Submit</Button>
          </Flex>
        </Flex>
      </MaxWidthContainer>
    </Box>
  );
};

export default NewsLetter;
