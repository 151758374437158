import React from "react";
import styled from "styled-components";

const SIZE = `${28}px`;

const SvgContainer = styled.div`
  width: ${SIZE};
  height: ${SIZE};
`;

export const LogoIcon = () => {
  return (
    <SvgContainer>
      <svg
        height={SIZE}
        width={SIZE}
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        style={{ enableBackground: "new 0 0 100 100" }}
        xmlSpace="preserve"
      >
        <path d="M72.3,12.3L72.3,12.3L72.3,12.3l-0.4-0.8H28.2L6.3,50l21.8,38.5h43.7L93.7,50L72.3,12.3z M33.3,25.3L9.9,47.7l18.6-32.8  L33.3,25.3z M70.7,14.8l6.4,35.7L36.9,25.8L70.7,14.8z M35.5,27.3l41,25.2l-41,23.3V27.3z M77,54.5l-7.2,30.8l-32.6-8.1L77,54.5z   M33.5,75L9.9,50.5l23.6-22.7V75z M33.2,77.6l-4.6,7.7L11.5,55.1L33.2,77.6z M35,78.6l31.4,7.9H30.2L35,78.6z M79.3,53.3l11.6-2.5  L72.1,84L79.3,53.3z M90.7,48.9l-11.4,2.5l-5.9-33.1L90.7,48.9z M35,24.3l-5-10.8h38.2L35,24.3z"></path>
      </svg>
    </SvgContainer>
  );
};
