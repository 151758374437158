import styled from "styled-components";
import { themeGet } from "styled-system";

export const CanvasContainer = styled.div`
  ${props => {
    return `
      & canvas {
        width: 100% !important;
        height: 100% !important;
      }

      & div {
        width: 100%;
        height: 100%;
      }

      max-height: ${props.height};
      max-width: ${props.width};

      background: white;
      box-shadow: 2px 2px 10px 2px ${themeGet("colors.midGray")(props)};
      padding: 16px;
    `;
  }};
`;
