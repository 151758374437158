import React from "react";
// import axios from "axios";
import { withRouter } from "react-router-dom";

import { Box } from "@/app/components";
import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";
import { Button } from "@/app/components/Button";

import { ProductSelection } from "@/app/sections/ProductSelection";

import { scrollToTop } from "@/app/utilities/misc";

// const postRequestToCloudFunction = async data => {
//   return await axios({
//     method: "post",
//     url:
//       "http://localhost:5000/rodandcones-1540343484211/us-central1/helloWorld",
//     data: data
//   });
// };

// const convertSelectedVariantStringToObj = selectedProductOptionsStr => {
//   const selectedProductOptionsArr = convertSelectedProductOptionsStringToArray(
//     selectedProductOptionsStr
//   );
//   const result = {};

//   if (!selectedProductOptionsArr) {
//     return result;
//   }

//   selectedProductOptionsArr.forEach(item => {
//     const { name, value } = item;
//     result[name] = value;
//   });

//   return result;
// };

export const convertSelectedProductOptionsStringToArray = selectedProductOptionsStr => {
  const result =
    selectedProductOptionsStr &&
    selectedProductOptionsStr.split(",").map(item => {
      const [name, value] = item.split(":");
      return {
        name,
        value
      };
    });

  return result;
};

export const convertSelectedProductOptionsObjToArray = selectedProductOptionsObj => {
  return Object.keys(selectedProductOptionsObj).map(key => {
    const value = selectedProductOptionsObj[key];
    return {
      name: key,
      value
    };
  });
};

class DesignCollection extends React.Component {
  componentDidMount = () => {
    scrollToTop();
  };

  getProductById = (products, id) => {
    if (!products) {
      return null;
    }

    const selectedProduct = products.find(product => product.id === id);
    return selectedProduct || null;
  };

  getCurrentVariant = (variantSelectionPerProduct, selectedProductId) => {
    return variantSelectionPerProduct[selectedProductId] || {};
  };

  getCurrentVariantString = (variantSelectionPerProduct, selectedProductId) => {
    const getCurrentVariantArray = () => {
      const variantOptions = this.getCurrentVariant(
        variantSelectionPerProduct,
        selectedProductId
      );

      return Object.keys(variantOptions).map(name => {
        const value = variantOptions[name];

        return {
          name,
          value
        };
      });
    };

    const variantOptionsArr = getCurrentVariantArray();
    const result = variantOptionsArr.reduce((acc, curr) => {
      const { name, value } = curr;
      const strCurr = `${name}:${value}`;

      return acc === "" ? strCurr : `${strCurr},${acc}`;
    }, "");

    return result;
  };

  handleSelectedVariant = (selectedProductId, optionName, optionValue) => {
    const variantSelectionPerProduct = {
      ...this.props.variantSelectionPerProduct
    };

    if (!variantSelectionPerProduct[selectedProductId]) {
      variantSelectionPerProduct[selectedProductId] = {};
    }

    variantSelectionPerProduct[selectedProductId][optionName] = optionValue;

    return variantSelectionPerProduct;
  };

  validateProductOptionSelection = (
    selectedProduct,
    variantSelectionPerProduct,
    selectedProductId
  ) => {
    const hasProductSelection = Boolean(selectedProductId);
    if (!hasProductSelection) {
      return false;
    }

    const availableVariantOptionNames = selectedProduct.options.map(
      optionObj => optionObj.name
    );
    const variantOptions = this.getCurrentVariant(
      variantSelectionPerProduct,
      selectedProductId
    );
    // check to see if we have captured the value for every available option for the given product;
    return availableVariantOptionNames.every(name =>
      Object.keys(variantOptions).includes(name)
    );
  };

  render() {
    const {
      products,
      history,
      match,
      location,
      updateURL,
      selectedProductId,
      selectedVariant,
      variantSelectionPerProduct
    } = this.props;

    const collectionId = match.params.designId;
    const selectedProduct = this.getProductById(products, selectedProductId);
    const productVariantOptions = this.getCurrentVariant(
      variantSelectionPerProduct,
      selectedProductId
    );

    const isValid = this.validateProductOptionSelection(
      selectedProduct,
      variantSelectionPerProduct,
      selectedProductId
    );

    return (
      <Box px={[10]} pt={10} pb={20}>
        <MaxWidthContainer>
          <Box>
            <ProductSelection
              products={products}
              selectedProduct={selectedProduct}
              selectedVariant={selectedVariant}
              variantOptions={productVariantOptions}
              onProductClick={selectedProductId => {
                updateURL([["productId", selectedProductId]]);
              }}
              onVariantSelect={(optionName, selectedValue) => {
                const variantSelectionPerProduct = this.handleSelectedVariant(
                  selectedProductId,
                  optionName,
                  selectedValue
                );

                updateURL([
                  ["productOptions", JSON.stringify(variantSelectionPerProduct)]
                ]);
              }}
            />
            <Button
              disabled={!isValid}
              onClick={() => {
                if (isValid) {
                  history.push(`/customize/${collectionId}${location.search}`);
                }
              }}
            >
              Next
            </Button>
          </Box>
        </MaxWidthContainer>
      </Box>
    );
  }
}

export default withRouter(DesignCollection);
