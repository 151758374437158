import React from "react";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";

import {
  createCheckout,
  checkoutLineItemsAdd,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
  checkoutCustomerAssociate
} from "./checkout";

const initialState = {};

const Context = React.createContext(initialState);

class AppStateProvider extends React.Component {
  state = {
    checkout: { lineItems: { edges: [] } }
  };

  componentWillMount() {
    this.props
      .createCheckout({
        variables: {
          input: {}
        }
      })
      .then(res => {
        this.setState({
          checkout: res.data.checkoutCreate.checkout
        });
      });
  }

  addVariantToCart = ({ variantId, quantity = 1, customAttributes = "" }) => {
    const formattedCustomAttributes = [
      {
        key: "properties",
        value: customAttributes
      }
    ];

    return this.props
      .checkoutLineItemsAdd({
        variables: {
          checkoutId: this.state.checkout.id,
          lineItems: [
            {
              variantId,
              quantity: parseInt(quantity, 10),
              customAttributes: formattedCustomAttributes
            }
          ]
        }
      })
      .then(res => {
        this.setState({
          checkout: res.data.checkoutLineItemsAdd.checkout
        });
      });
  };

  updateLineItemInCart = (lineItemId, quantity) => {
    this.props
      .checkoutLineItemsUpdate({
        variables: {
          checkoutId: this.state.checkout.id,
          lineItems: [{ id: lineItemId, quantity: parseInt(quantity, 10) }]
        }
      })
      .then(res => {
        this.setState({
          checkout: res.data.checkoutLineItemsUpdate.checkout
        });
      });
  };

  removeLineItemInCart = lineItemId => {
    this.props
      .checkoutLineItemsRemove({
        variables: {
          checkoutId: this.state.checkout.id,
          lineItemIds: [lineItemId]
        }
      })
      .then(res => {
        this.setState({
          checkout: res.data.checkoutLineItemsRemove.checkout
        });
      });
  };

  render() {
    const { children } = this.props;
    return (
      <Context.Provider
        value={{
          ...this.state,
          addVariantToCart: this.addVariantToCart,
          removeLineItemInCart: this.removeLineItemInCart,
          updateLineItemInCart: this.updateLineItemInCart
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}

const query = gql`
  query query {
    shop {
      name
      description
    }
  }
`;

const AppStateProviderWithDataAndMutation = compose(
  graphql(query),
  graphql(createCheckout, { name: "createCheckout" }),
  graphql(checkoutLineItemsAdd, { name: "checkoutLineItemsAdd" }),
  graphql(checkoutLineItemsUpdate, { name: "checkoutLineItemsUpdate" }),
  graphql(checkoutLineItemsRemove, { name: "checkoutLineItemsRemove" }),
  graphql(checkoutCustomerAssociate, { name: "checkoutCustomerAssociate" })
)(AppStateProvider);

export const Provider = AppStateProviderWithDataAndMutation;
export const Consumer = Context.Consumer;

export default AppStateProviderWithDataAndMutation;
