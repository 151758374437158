import * as artwork from "@/app/artwork";

export const idToName = {
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjI3NzU3MTYwNg==": "accurateQuilt",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjI3Nzc2ODIxNA==": "bandLeo",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjI3NzYwNDM3NA==": "cheerfulUmbra",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjI3NzYzNzE0Mg==": "fluChin",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjI3NzY2OTkxMA==": "ironTeacher",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjI3NzcwMjY3OA==": "laserSyndrome",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjI3NzczNTQ0Ng==": "pillowTrolley",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjI3NzUwNjA3MA==": "simplisticJudo",
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1NjI3NzUzODgzOA==": "pureMayor"
};

export default {
  accurateQuilt: {
    config: artwork.accurateQuiltConfig,
    sketch: artwork.accurateQuilt
  },
  bandLeo: {
    config: artwork.bandLeoConfig,
    sketch: artwork.bandLeo
  },
  cheerfulUmbra: {
    config: artwork.cheerfulUmbraConfig,
    sketch: artwork.cheerfulUmbra
  },
  fluChin: {
    config: artwork.fluChinConfig,
    sketch: artwork.fluChin
  },
  ironTeacher: {
    config: artwork.ironTeacherConfig,
    sketch: artwork.ironTeacher
  },
  laserSyndrome: {
    config: artwork.laserSyndromeConfig,
    sketch: artwork.laserSyndrome
  },
  pillowTrolley: {
    config: artwork.pillowTrolleyConfig,
    sketch: artwork.pillowTrolley
  },
  simplisticJudo: {
    config: artwork.simplisticJudoConfig,
    sketch: artwork.simplisticJudo
  },
  pureMayor: {
    config: artwork.pureMayorConfig,
    sketch: artwork.pureMayor
  }
};
