// theme.js
const theme = {
  colors: {
    black: "#111",
    white: "#fff",
    blue: "#007ce0",
    darkBlue: "#0c0d35",
    darkGray: "#313131",
    lightGray: "#ececec",
    midGray: "#afafaf",
    gray: "#676767",
    pink: "#f11778"
  },
  fontSizes: {
    0: "4rem",
    1: "3rem",
    2: "2.5rem",
    3: "2rem",
    4: "1.5rem",
    5: "1.25rem",
    6: "1rem",
    7: "0.875rem",
    8: "0.75rem"
  },
  breakpoints: ["576px", "992px", "1200px"],
  space: [
    0,
    4,
    8,
    12,
    16,
    20,
    24,
    28,
    32,
    36,
    40,
    44,
    48,
    52,
    56,
    60,
    64,
    68,
    72,
    76,
    80,
    100,
    120,
    140,
    160,
    180,
    200
  ],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  fonts: {
    heading: "'Montserrat', sans-serif",
    body: "'Roboto', sans-serif"
  },
  lineHeights: {
    solid: 1,
    title: 1.33,
    copy: 1.66,
    copyOnDarkBg: 1.8
  }
};

export const getBreakPoint = screenSize => {
  let index;

  switch (screenSize) {
    case "mobile":
      index = 0;
      break;
    case "tablet":
      index = 1;
      break;
    case "desktop":
      index = 2;
      break;
    default:
      index = 2;
  }

  const breakpoint = theme.breakpoints[index];
  return parseInt(breakpoint, 10);
};

export default theme;
