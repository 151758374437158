import { random } from "canvas-sketch-util";
import { lerp } from "canvas-sketch-util/math";

import {
  getConfigValueIfUndefined,
  getValueIfUndefined
} from "../../utilities/misc";

export const config = [
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 1906,
    label: "Seed Value"
  },
  {
    name: "colorTheme",
    type: "colorPalette",
    defaultValue: ["#fc354c", "#29221f", "#13747d", "#0abfbc", "#fcf7c5"],
    label: "Color Palette"
  },
  {
    name: "count",
    type: "scalar",
    min: 2,
    max: 500,
    defaultValue: 50,
    label: "Count"
  },
  {
    name: "minRadius",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.2,
    label: "Min Outer Radius"
  },
  {
    name: "maxRadius",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.61,
    label: "Max Outer Radius"
  },
  {
    name: "minRadiusInner",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.228,
    label: "Min Inner Radius"
  },
  {
    name: "maxRadiusInner",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.266,
    label: "Max Inner Radius"
  },
  {
    name: "sphereMinRadius",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.106,
    label: "Sphere Min Radius"
  },
  {
    name: "sphereMaxRadius",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.569,
    label: "Sphere Max Radius"
  },
  {
    name: "strokeWeight",
    type: "float",
    min: 1,
    max: 10,
    defaultValue: 1.5,
    label: "Stroke Weight"
  },
  {
    name: "colorAmount",
    type: "scalar",
    min: 1,
    max: 5,
    defaultValue: 5,
    label: "Color Amount"
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#556270",
    label: "Background Color"
  }
];

const sketch = p => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let count = getConfigValueIfUndefined(config, configFromState, "count");
  let seedValue = getConfigValueIfUndefined(
    config,
    configFromState,
    "seedValue"
  );

  let minRadius = getConfigValueIfUndefined(
    config,
    configFromState,
    "minRadius"
  );
  let maxRadius = getConfigValueIfUndefined(
    config,
    configFromState,
    "maxRadius"
  );

  let minRadiusInner = getConfigValueIfUndefined(
    config,
    configFromState,
    "minRadiusInner"
  );
  let maxRadiusInner = getConfigValueIfUndefined(
    config,
    configFromState,
    "maxRadiusInner"
  );

  let sphereMinRadius = getConfigValueIfUndefined(
    config,
    configFromState,
    "sphereMinRadius"
  );
  let sphereMaxRadius = getConfigValueIfUndefined(
    config,
    configFromState,
    "sphereMaxRadius"
  );

  let strokeWeight = getConfigValueIfUndefined(
    config,
    configFromState,
    "strokeWeight"
  );

  let colorAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorAmount"
  );
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");
  let colorTheme = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorTheme"
  );

  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = props => {
    if (props.count) {
      count = parseInt(props.count, 10);
    }

    if (props.seedValue) {
      seedValue = parseInt(props.seedValue, 10);
    }

    if (props.minRadius) {
      minRadius = parseFloat(props.minRadius);
    }

    if (props.maxRadius) {
      maxRadius = parseFloat(props.maxRadius);
    }

    if (props.minRadiusInner) {
      minRadiusInner = parseFloat(props.minRadiusInner);
    }

    if (props.maxRadiusInner) {
      maxRadiusInner = parseFloat(props.maxRadiusInner);
    }

    if (props.sphereMinRadius) {
      sphereMinRadius = parseFloat(props.sphereMinRadius);
    }

    if (props.sphereMaxRadius) {
      sphereMaxRadius = parseFloat(props.sphereMaxRadius);
    }

    if (props.bgColor) {
      bgColor = props.bgColor;
    }

    if (props.colorTheme) {
      colorTheme = props.colorTheme;
    }

    if (props.colorAmount) {
      colorAmount = parseInt(props.colorAmount, 10);
    }

    if (props.strokeWeight) {
      strokeWeight = parseFloat(props.strokeWeight);
    }
  };

  p.setup = () => {
    p.createCanvas(1024, 1024);
    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(24);
    p.pixelDensity(pixelDensity);
  };

  p.draw = () => {
    random.setSeed(seedValue);
    p.background(bgColor);

    const width = p.width;
    const height = p.height;
    const originX = width / 2;
    const originY = height / 2;

    p.push();
    p.translate(originX, originY);
    if (givenState.homeScreen) {
      p.rotate(p.frameCount);
    }

    let iterations = 360 / count;
    for (let i = 0; i < 360; i += iterations) {
      const randomValue2 = random.value();
      const lineEndPoint = lerp(
        (minRadius * width) / 2,
        (maxRadius * width) / 2,
        randomValue2
      );
      const lineStartPoint = lerp(
        (minRadiusInner * width) / 2,
        (maxRadiusInner * width) / 2,
        randomValue2
      );

      const randomValue3 = random.value();
      const sphereSize = lerp(
        sphereMinRadius * width * 0.15,
        sphereMaxRadius * width * 0.15,
        randomValue3
      );

      const randomColor = random.pick(colorTheme.slice(0, colorAmount));

      p.push();

      p.translate(0, 0);
      p.rotate(i);

      p.stroke(randomColor);
      p.strokeWeight(strokeWeight * width * 0.0025);
      p.line(lineStartPoint, lineStartPoint, lineEndPoint, lineEndPoint);

      p.fill(randomColor);
      p.noStroke();
      p.ellipse(lineEndPoint, lineEndPoint, sphereSize, sphereSize);

      p.pop();
    }

    p.pop();
  };
};

export default sketch;
