import theme from "./theme";

export const border = () => {
  return `1px solid ${theme.colors.lightGray}`;
};

export const borderSelected = () => {
  return `1px solid ${theme.colors.blue}`;
};

export const boxShadow = () => {
  return `2px 2px 10px 2px ${theme.colors.lightGray}`;
};
