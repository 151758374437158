import React from "react";

import { Card, Flex } from "@/app/components";
import { Image, ImageContainer, ImageWithWall } from "./index.components";

const ImageCard = ({ path }) => {
  return (
    <Card>
      <Flex
        display="flex"
        flexDirection="column"
        justifyContent={"space-between"}
      >
        <ImageContainer position={"relative"}>
          <Image src={path} />
          <ImageWithWall path={path} />
        </ImageContainer>
      </Flex>
    </Card>
  );
};

export { ImageCard };
