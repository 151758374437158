import { random } from "canvas-sketch-util";
import colors from "nice-color-palettes";

import { getGrid } from "../../utilities/grid";
import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined
} from "../../utilities/misc";

export const config = [
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 1906,
    label: "Seed Value"
  },
  {
    name: "countX",
    type: "scalar",
    min: 2,
    max: 50,
    defaultValue: 23,
    label: "Count X"
  },
  {
    name: "countY",
    type: "scalar",
    min: 2,
    max: 50,
    defaultValue: 21,
    label: "Count Y"
  },
  {
    name: "margin",
    type: "float",
    min: 0,
    max: 0.5,
    defaultValue: 0.152,
    label: "Margin"
  },
  {
    name: "rectSizeX",
    type: "float",
    min: 1,
    max: 400,
    defaultValue: 47,
    label: "Rect Size X"
  },
  {
    name: "rectSizeY",
    type: "float",
    min: 1,
    max: 200,
    defaultValue: 12,
    label: "Rect Size Y"
  },
  {
    name: "colorAmount",
    type: "scalar",
    min: 1,
    max: 5,
    defaultValue: 5,
    label: "Color Amount"
  },
  {
    name: "removalAmount",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.741,
    label: "Removal Amount"
  },
  {
    name: "colorTheme",
    type: "scalar",
    min: 0,
    max: 99,
    defaultValue: 10,
    label: "Color Theme"
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#000",
    label: "Background Color"
  }
];

const sketch = p => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let countX = getConfigValueIfUndefined(config, configFromState, "countX");
  let countY = getConfigValueIfUndefined(config, configFromState, "countY");
  let margin = getConfigValueIfUndefined(config, configFromState, "margin");
  let rectSizeX = getConfigValueIfUndefined(
    config,
    configFromState,
    "rectSizeX"
  );
  let rectSizeY = getConfigValueIfUndefined(
    config,
    configFromState,
    "rectSizeY"
  );
  let colorTheme = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorTheme"
  );
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");
  let seedValue = getConfigValueIfUndefined(
    config,
    configFromState,
    "seedValue"
  );
  let colorAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorAmount"
  );
  let removalAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "removalAmount"
  );

  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = props => {
    if (exists(props.seedValue)) {
      seedValue = parseInt(props.seedValue, 10);
    }

    if (exists(props.countX)) {
      countX = parseInt(props.countX, 10);
    }

    if (exists(props.countY)) {
      countY = parseInt(props.countY, 10);
    }

    if (exists(props.margin)) {
      margin = parseFloat(props.margin);
    }

    if (exists(props.rectSizeX)) {
      rectSizeX = parseFloat(props.rectSizeX);
    }

    if (exists(props.rectSizeY)) {
      rectSizeY = parseFloat(props.rectSizeY);
    }

    if (exists(props.colorAmount)) {
      colorAmount = props.colorAmount;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }

    if (exists(props.colorTheme)) {
      colorTheme = props.colorTheme;
    }

    if (exists(props.removalAmount)) {
      removalAmount = parseFloat(props.removalAmount);
    }
  };

  p.setup = () => {
    p.createCanvas(1024, 1024);
    p.pixelDensity(pixelDensity);
    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(6);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    random.setSeed(seedValue);
    p.background(bgColor);
    p.noStroke();

    let points = getGrid({
      countX,
      countY,
      margin,
      removalAmount
    });

    const width = p.width;
    const height = p.height;

    points.forEach(item => {
      const { position, toBeRemoved } = item;
      if (toBeRemoved) {
        return;
      }

      let [u, v] = position;

      let x = u * width;
      let y = v * height;

      const randomColor = random.pick(colors[colorTheme].slice(0, colorAmount));

      p.fill(randomColor);
      p.rect(x, y, rectSizeX, rectSizeY);
    });
  };
};

export default sketch;
