import React from "react";
import marked from "marked";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Fade } from "@/app/components/Fade";

import { Box, Flex, Text, BodyText } from "@/app/components";
import { Button } from "@/app/components/Button";
import { LayoutWrapper } from "@/app/components/LayoutWrapper";
import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";

import DesignDisplay from "@/app/components/DesignDisplay";
import DesignWithController from "@/app/components/DesignWithController";

import { pureMayor, pureMayorConfig } from "@/app/artwork/";
import theme from "@/app/styles/theme";

import content from "./content.json";

const BodyTextCustom = styled(BodyText)`
  a {
    color: ${theme.colors.pink};
    font-weight: ${theme.fontWeights[5]};
  }
`;

const Paragraph = ({ children, ...props }) => {
  return (
    <BodyTextCustom
      fontSize={6}
      lineHeight={"copy"}
      textAlign={"left"}
      fontFamily={"body"}
      {...props}
    >
      {children}
    </BodyTextCustom>
  );
};

const Subtitle = ({ children }) => {
  return (
    <Text fontSize={4} textAlign={"left"} fontWeight={"600"}>
      {children}
    </Text>
  );
};

const MainTitle = ({ children }) => {
  return (
    <Text fontSize={3} textAlign={"left"} fontWeight={"900"}>
      {children}
    </Text>
  );
};

const CenteredContainer = ({ children }) => {
  return <Flex justifyContent={"center"}>{children}</Flex>;
};

const createMarkup = content => {
  return { __html: content };
};

class About extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    window.scrollTo(0, 0);
    this.props.history.push(`/shop`);
  }

  render() {
    const allContent = content.map((contentItem, key) => {
      let { type, content: contentBody } = contentItem;

      if (type === "title") {
        return (
          <Box pb={[5]} key={key}>
            <MainTitle>{contentBody}</MainTitle>
          </Box>
        );
      }

      if (type === "subtitle") {
        return (
          <Box pb={[5]} key={key}>
            <Subtitle>{contentBody}</Subtitle>
          </Box>
        );
      }

      if (type === "text") {
        contentBody = marked(contentBody);
        return (
          <Box pb={[5]} key={key}>
            <Paragraph dangerouslySetInnerHTML={createMarkup(contentBody)} />
          </Box>
        );
      }

      if (type === "art_01") {
        return (
          <Box pb={5} key={key}>
            <CenteredContainer>
              <DesignDisplay
                state={{}}
                sketch={pureMayor}
                aspectRatio={{ x: 1, y: 1 }}
                width={"100%"}
              />
            </CenteredContainer>
          </Box>
        );
      }

      if (type === "art_02") {
        return (
          <Box pb={5} key={key}>
            <CenteredContainer>
              <DesignWithController
                configuration={pureMayorConfig}
                sketchProportions={{ x: 1, y: 1 }}
                sketch={pureMayor}
                fullHeightController={false}
              ></DesignWithController>
            </CenteredContainer>
          </Box>
        );
      }

      return null;
    });

    return (
      <LayoutWrapper>
        <Fade>
          <Box px={[10, 20]} py={[10, 20]} pb={10}>
            <MaxWidthContainer width={"720px"}>
              {allContent}
              <Flex flexDirection={["column", "column", "row"]}>
                <Box mr={[0, 0, 2]} width={1} mb={[2, 2, 0]}>
                  <Button fullWidth onClick={this.handleClick} type={"pink"}>
                    Choose an Artwork
                  </Button>
                </Box>
                <Box ml={[0, 0, 2]} width={1}>
                  <Button
                    fullWidth
                    onClick={() => {
                      window.location.href =
                        "https://www.codingforvisuallearners.com";
                      return null;
                    }}
                  >
                    Learn More
                  </Button>
                </Box>
              </Flex>
            </MaxWidthContainer>
          </Box>
        </Fade>
      </LayoutWrapper>
    );
  }
}

export default withRouter(About);
