import colors from "nice-color-palettes";
import seedrandom from "seedrandom";

import { rand } from "../../utilities/random";
import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined
} from "../../utilities/misc";

export const config = [
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 1343,
    label: "Seed Value"
  },
  {
    name: "rectSize",
    type: "float",
    min: 25,
    max: 450,
    defaultValue: 100,
    label: "Shape Size"
  },
  {
    name: "colorTheme",
    type: "scalar",
    min: 0,
    max: 99,
    defaultValue: 20,
    label: "Color Theme"
  },
  {
    name: "colorAmount",
    type: "scalar",
    min: 2,
    max: 5,
    defaultValue: 4,
    label: "Color Amount"
  }
];

const sketch = p => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let seedValue = getConfigValueIfUndefined(
    config,
    configFromState,
    "seedValue"
  );
  let rectSize = getConfigValueIfUndefined(config, configFromState, "rectSize");
  let colorTheme = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorTheme"
  );
  let colorAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorAmount"
  );

  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = props => {
    if (exists(props.rectSize)) {
      rectSize = parseFloat(props.rectSize);
    }

    if (exists(props.colorTheme)) {
      colorTheme = parseInt(props.colorTheme, 10);
    }

    if (exists(props.seedValue)) {
      seedValue = parseInt(props.seedValue, 10);
    }

    if (exists(props.colorAmount)) {
      colorAmount = props.colorAmount;
    }
  };

  p.setup = () => {
    let sizeX = 1024;
    let sizeY = sizeX;
    p.createCanvas(sizeX, sizeY);
    p.rectMode(p.CENTER);
    p.frameRate(6);
    p.pixelDensity(pixelDensity);
  };

  p.draw = () => {
    let colorPalette1 = colors[colorTheme];
    let colorPalette2 = colorPalette1;

    const rectAmountX = p.width / rectSize;
    const rectAmountY = p.height / rectSize;
    const rectOffset = rectSize / 2;
    const seed = seedrandom(String(seedValue))();

    for (let i = 0; i <= rectAmountX + 1; i++) {
      for (let j = 0; j <= rectAmountY + 1; j++) {
        p.push();

        const seedMult = (i + 10) * (j + 10);

        const randomDirectionNumber = parseInt(
          rand(0, 2, seed * seedMult * 40),
          10
        );

        const randomRectColorIndex = parseInt(
          rand(0, colorAmount, seed * seedMult * 10),
          10
        );
        const bg = colorPalette1[randomRectColorIndex];
        p.fill(bg);
        p.noStroke();

        p.rect(
          i * rectSize + rectOffset,
          j * rectSize + rectOffset,
          rectSize,
          rectSize
        );
        p.pop();

        // h = horizontal, v = vertical
        const arcDirection = randomDirectionNumber === 0 ? "h" : "v";
        const arcStart = arcDirection === "h" ? p.PI / 2 : p.PI;
        const arcEnd = arcDirection === "h" ? p.PI * 1.5 : p.PI * 2;

        const randomSphereAColorIndex = parseInt(
          rand(0, colorAmount, seed * seedMult * 20),
          10
        );
        let color1 = colorPalette2[randomSphereAColorIndex];
        p.push();
        p.noStroke();
        p.fill(color1);
        p.translate(i * rectSize, j * rectSize);
        p.rotate(p.PI);
        p.arc(0, 0, rectSize, rectSize, arcStart, arcEnd);
        p.pop();

        const randomSphereBColorIndex = parseInt(
          rand(0, colorAmount, seed * seedMult * 30),
          10
        );
        let color2 = colorPalette2[randomSphereBColorIndex];
        p.push();
        p.noStroke();
        p.fill(color2);
        p.translate(i * rectSize, j * rectSize);
        p.rotate(p.PI);
        p.arc(0, 0, rectSize, rectSize, arcEnd, arcStart);
        p.pop();
      }
    }
  };
};

export default sketch;
