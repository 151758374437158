import React, { Component } from "react";
import CustomerAuthWithMutation from "./CustomerAuth";

class CustomerAuthentication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountVerificationMessage: false
    };

    this.showAccountVerificationMessage = this.showAccountVerificationMessage.bind(
      this
    );
  }

  showAccountVerificationMessage() {
    this.setState({ accountVerificationMessage: true });
    setTimeout(() => {
      this.setState({
        accountVerificationMessage: false
      });
    }, 5000);
  }

  render() {
    const {
      associateCustomerCheckout,
      closeCustomerAuth,
      isCustomerAuthOpen,
      isNewCustomer
    } = this.props;

    return (
      <div className="App">
        <div className="Flash__message-wrapper">
          <p
            className={`Flash__message ${
              this.state.accountVerificationMessage
                ? "Flash__message--open"
                : ""
            }`}
          >
            We have sent you an email, please click the link included to verify
            your email address
          </p>
        </div>
        <CustomerAuthWithMutation
          closeCustomerAuth={closeCustomerAuth}
          isCustomerAuthOpen={isCustomerAuthOpen}
          newCustomer={isNewCustomer}
          associateCustomerCheckout={associateCustomerCheckout}
          showAccountVerificationMessage={this.showAccountVerificationMessage}
        />
      </div>
    );
  }
}

export default CustomerAuthentication;
