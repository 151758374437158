import React, { useEffect } from "react";
import MediaQuery from "react-responsive";
import { Element, Events, scroller } from "react-scroll";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { getBreakPoint } from "@/app/styles/theme";

import { Fade } from "@/app/components/Fade";

import { SelectProduct } from "./components/SelectProduct/";
import { Product } from "./components/Product";
import {
  SelectedProductParameters,
  singleVariantLabelOverride
} from "./components/SelectedProductParameters";

import { Box, Flex, Text, BodyText } from "@/app/components/Primitives";

const PriceDisplay = styled(Text)`
  ${props => {
    return `
      background: ${themeGet("colors.lightGray")(props)};
      padding: 4px;
      border-radius: 4px;
    `;
  }}
`;

function createMarkup(content) {
  return { __html: content };
}

export const ProductSelection = props => {
  const {
    onProductClick,
    onVariantSelect,
    products,
    selectedProduct,
    selectedVariant,
    variantOptions
  } = props;

  useEffect(() => {
    Events.scrollEvent.register("begin", function(to, element) {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function(to, element) {
      // console.log("end", arguments);
    });

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const scrollTo = () => {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 150,
      smooth: "easeInOutQuart"
    });
  };

  const tabletBreakpoint = getBreakPoint("tablet");
  const product = selectedProduct || {};
  const hasSelectedProduct = product && Object.keys(product).length > 0;
  const { descriptionHtml, id, title } = product;
  let price = null;
  if (selectedVariant) {
    price = `${selectedVariant.price}$`;
  }

  useEffect(() => {
    if (hasSelectedProduct) {
      scrollTo();
    }
  }, []);

  const getDescriptionImage = () => {
    if (selectedVariant) {
      return (
        <Product
          imagePath={selectedVariant.image.originalSrc}
          price={selectedVariant.price}
          title={
            selectedVariant.title === singleVariantLabelOverride.old
              ? singleVariantLabelOverride.new
              : selectedVariant.title
          }
        />
      );
    }

    if (hasSelectedProduct) {
      return (
        <Product
          imagePath={product.image}
          priceRange={product.priceRange}
          title={product.title}
        />
      );
    }
  };

  const productDescriptionSectionMarkup = id && (
    <Box mb={5}>
      <Flex alignItems={"flex-end"} mb={5}>
        <Text fontSize={3} fontWeight={"600"} mr={3}>
          {title}
        </Text>
        <PriceDisplay fontSize={5} fontWeight={"400"}>
          {price && `${price}`}
        </PriceDisplay>
      </Flex>

      <Box width={1} mb={5}>
        <Flex flexDirection={["column", "column", "row"]}>
          <Box width={[1, 1, 0.4]} mr={10}>
            {getDescriptionImage()}
          </Box>

          <Box width={[1, 1, 0.5]}>
            <Flex flexDirection={"column"}>
              <Box mb={5}>
                <BodyText
                  lineHeight={"copy"}
                  fontFamily={"body"}
                  dangerouslySetInnerHTML={createMarkup(descriptionHtml)}
                ></BodyText>
              </Box>
              <Box>
                <Flex flexDirection={"column"}>
                  <SelectedProductParameters
                    onChange={onVariantSelect}
                    allOptions={product.options}
                    selectionState={variantOptions}
                  />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );

  return (
    <MediaQuery minWidth={tabletBreakpoint}>
      {matches => {
        const isDesktop = matches;
        return (
          <Box>
            <Text fontSize={3} fontWeight={"900"} mb={10}>
              Step 1: Select a Product
            </Text>

            <Fade>
              <>
                <Box mb={12}>
                  <SelectProduct
                    onClick={val => {
                      scrollTo();
                      onProductClick(val);
                    }}
                    selectedProductId={id}
                    products={products}
                    displayChildren={!isDesktop}
                  >
                    <Element name="scroll-to-element">
                      {productDescriptionSectionMarkup}
                    </Element>
                  </SelectProduct>
                </Box>
                {isDesktop && (
                  <Element name="scroll-to-element">
                    {productDescriptionSectionMarkup}
                  </Element>
                )}
              </>
            </Fade>
          </Box>
        );
      }}
    </MediaQuery>
  );
};
