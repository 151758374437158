import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withRouter, Redirect } from "react-router-dom";

import Layout from "@/app/components/Layout";
import URLUpdateProvider from "@/app/components/URLUpdateProvider";
import LoadingIcon from "@/app/components/LoadingIcon";
import * as AppContext from "@/app/App.context";

import CustomizeYourDesign from "./CustomizeYourDesign";
import { convertSelectedProductOptionsObjToArray } from "../DesignCollection/DesignCollection";
import { parseProductOptionsQuery } from "../DesignCollection/DesignCollection.provider";

const CustomizeYourDesignProvider = ({ match }) => {
  const collectionId = match.params.designId;

  return (
    <AppContext.Consumer>
      {appContext => {
        const {
          addVariantToCart,
          checkout,
          removeLineItemInCart,
          updateLineItemInCart
        } = appContext;

        const layoutProps = {
          checkout,
          removeLineItemInCart,
          updateLineItemInCart,
          displayNewsletter: false
        };

        return (
          <URLUpdateProvider>
            {(_updateURL, queryObj) => {
              const designSettings = queryObj && queryObj["state"];
              const id = queryObj && queryObj["productId"];
              const productOptionsStr = queryObj && queryObj["productOptions"];
              const selectedProductOptions = parseProductOptionsQuery(
                productOptionsStr
              )[id];
              const selectedProductOptionsArr = convertSelectedProductOptionsObjToArray(
                selectedProductOptions
              );

              if (
                !id ||
                !productOptionsStr ||
                !Array.isArray(selectedProductOptionsArr)
              ) {
                return <Redirect to={`/design/${collectionId}`} />;
              }

              return (
                <Layout {...layoutProps}>
                  {layoutContext => {
                    const {
                      handleOpenCheckout,
                      handleCartOpen
                    } = layoutContext;

                    return (
                      <Query
                        query={query}
                        variables={{
                          id,
                          selectedOptions: selectedProductOptionsArr
                        }}
                      >
                        {({ loading, error, data }) => {
                          if (loading || !data) {
                            return <LoadingIcon />;
                          }
                          if (error) {
                            console.error(error.message);
                            return (
                              <Redirect
                                to={{
                                  pathname: `/design/${id}`
                                }}
                              />
                            );
                          }

                          return (
                            <CustomizeYourDesign
                              addVariantToCart={addVariantToCart}
                              collectionId={collectionId}
                              data={data}
                              designSettings={designSettings}
                              id={id}
                              handleCartOpen={handleCartOpen}
                              selectedProductOptions={selectedProductOptionsArr}
                              handleOpenCheckout={handleOpenCheckout}
                            />
                          );
                        }}
                      </Query>
                    );
                  }}
                </Layout>
              );
            }}
          </URLUpdateProvider>
        );
      }}
    </AppContext.Consumer>
  );
};

export default withRouter(CustomizeYourDesignProvider);

const query = gql`
  query getVariantData($id: ID!, $selectedOptions: [SelectedOptionInput!]!) {
    node(id: $id) {
      id
      ... on Product {
        variantBySelectedOptions(selectedOptions: $selectedOptions) {
          id
        }
      }
    }
  }
`;
