import React from "react";

import { Text } from "@/app/components/Primitives";

export const ShortDescriptionText = ({ children }) => {
  return (
    <Text
      fontSize={4}
      fontWeight={"600"}
      pb={8}
      textAlign="left"
      lineHeight={"title"}
    >
      {children}
    </Text>
  );
};
