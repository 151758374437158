import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { Twitter, Instagram, Facebook, Send } from "react-feather";
import { Link } from "react-router-dom";

import { Box, Flex, Text } from "@/app/components";
import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";

const FooterSocialIcon = styled.div`
  ${props => {
    return `
      color: ${themeGet("colors.midGray")(props)}
      padding: 10px;
      margin-right: 8px;
      cursor: pointer;
    `;
  }}
`;
const FooterSocial = () => {
  const iconProps = { size: "24px", fill: "none" };

  return (
    <Box>
      <Flex>
        <FooterSocialIcon>
          <Twitter {...iconProps} />
        </FooterSocialIcon>
        <FooterSocialIcon>
          <Instagram {...iconProps} />
        </FooterSocialIcon>
        <FooterSocialIcon>
          <Facebook {...iconProps} />
        </FooterSocialIcon>
        <FooterSocialIcon>
          <Send {...iconProps} />
        </FooterSocialIcon>
      </Flex>
    </Box>
  );
};

const FooterNavigationLink = ({ pathname, name }) => {
  return (
    <Box mx={3}>
      <Link
        to={{
          pathname: pathname
        }}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <Text color={"lightGrey"} fontSize={7}>
          {name}
        </Text>
      </Link>
    </Box>
  );
};

const FooterNavigation = () => {
  return (
    <Box>
      <Flex mb={4} flexDirection={["column", "row"]}>
        <FooterNavigationLink pathname={"/"} name={"Home"} />
        <FooterNavigationLink pathname={"/shop"} name={"Shop"} />
        <FooterNavigationLink pathname={"/about"} name={"About"} />
        <FooterNavigationLink
          pathname={"/shipping-and-returns"}
          name={"Shipping & Returns"}
        />
        <FooterNavigationLink pathname={"/contact"} name={"Contact"} />
      </Flex>
      <Text color={"lightGrey"} fontSize={8} mx={3}>
        © 2020 ProceduralArt. All Rights Reserved.
      </Text>
    </Box>
  );
};

class Footer extends React.Component {
  render() {
    return (
      <Box bg={"black"} px={[10, 10, 10, 0]} py={10}>
        <MaxWidthContainer>
          <Flex
            justifyContent={"space-between"}
            flexDirection={["column", "column", "row"]}
          >
            <FooterSocial />
            <FooterNavigation />
          </Flex>
        </MaxWidthContainer>
      </Box>
    );
  }
}

export default Footer;
