import React, { Component } from "react";

import { LandingPage } from "./sections/LandingPage";

class App extends Component {
  render() {
    return <LandingPage />;
  }
}

export default App;
