import { random } from "canvas-sketch-util";
import { lerp } from "canvas-sketch-util/math";
import colors from "nice-color-palettes";

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined
} from "../../utilities/misc";

export const config = [
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 1558,
    label: "Seed Value"
  },
  {
    name: "innerRadius",
    type: "float",
    min: 0,
    max: 5,
    defaultValue: 0.336,
    label: "Radial Frame"
  },
  {
    name: "strokeWeight",
    type: "float",
    min: 0,
    max: 10,
    defaultValue: 2,
    label: "Stroke Weight"
  },
  {
    name: "xCount",
    type: "scalar",
    min: 1,
    max: 50,
    defaultValue: 10,
    label: "Horizontal Count"
  },
  {
    name: "xVariation",
    type: "float",
    min: 0,
    max: 1000,
    defaultValue: 1000,
    label: "Horizontal Variation"
  },
  {
    name: "yCount",
    type: "scalar",
    min: 1,
    max: 50,
    defaultValue: 3,
    label: "Vertical Count"
  },
  {
    name: "yVariation",
    type: "float",
    min: 0,
    max: 1000,
    defaultValue: 100,
    label: "Vertical Variation"
  },
  {
    name: "sphereAmount",
    type: "scalar",
    min: 1,
    max: 100,
    defaultValue: 50,
    label: "Sphere Amount"
  },
  {
    name: "minSphereSize",
    type: "float",
    min: 0,
    max: 100,
    defaultValue: 20,
    label: "Min. Sphere Size"
  },
  {
    name: "maxSphereSize",
    type: "float",
    min: 0,
    max: 200,
    defaultValue: 100,
    label: "Max. Sphere Size"
  },

  {
    name: "fgColor",
    type: "color",
    defaultValue: colors[2][0],
    label: "Foreground Color"
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: colors[2][1],
    label: "Background Color"
  }
];

const sketch = p => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let seedValue = getConfigValueIfUndefined(
    config,
    configFromState,
    "seedValue"
  );
  let strokeWeight = getConfigValueIfUndefined(
    config,
    configFromState,
    "strokeWeight"
  );
  let xCount = getConfigValueIfUndefined(config, configFromState, "xCount");
  let xVariation = getConfigValueIfUndefined(
    config,
    configFromState,
    "xVariation"
  );
  let yCount = getConfigValueIfUndefined(config, configFromState, "yCount");
  let yVariation = getConfigValueIfUndefined(
    config,
    configFromState,
    "yVariation"
  );
  let sphereAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "sphereAmount"
  );
  let minSphereSize = getConfigValueIfUndefined(
    config,
    configFromState,
    "minSphereSize"
  );
  let maxSphereSize = getConfigValueIfUndefined(
    config,
    configFromState,
    "maxSphereSize"
  );
  let innerRadius = getConfigValueIfUndefined(
    config,
    configFromState,
    "innerRadius"
  );
  let fgColor = getConfigValueIfUndefined(config, configFromState, "fgColor");
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = props => {
    if (exists(props.seedValue)) {
      seedValue = parseInt(props.seedValue, 10);
    }

    if (exists(props.strokeWeight)) {
      strokeWeight = parseFloat(props.strokeWeight);
    }

    if (exists(props.xCount)) {
      xCount = parseInt(props.xCount, 10);
    }

    if (exists(props.xVariation)) {
      xVariation = parseFloat(props.xVariation);
    }

    if (exists(props.yCount)) {
      yCount = parseInt(props.yCount, 10);
    }

    if (exists(props.yVariation)) {
      yVariation = parseFloat(props.yVariation);
    }

    if (exists(props.sphereAmount)) {
      sphereAmount = parseInt(props.sphereAmount, 10);
    }

    if (exists(props.minSphereSize)) {
      minSphereSize = parseInt(props.minSphereSize, 10);
    }

    if (exists(props.maxSphereSize)) {
      maxSphereSize = parseInt(props.maxSphereSize, 10);
    }

    if (exists(props.innerRadius)) {
      innerRadius = parseFloat(props.innerRadius);
    }

    if (exists(props.fgColor)) {
      fgColor = props.fgColor;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }
  };

  p.setup = () => {
    p.createCanvas(1024, 1024);
    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(6);
    p.pixelDensity(pixelDensity);
  };

  p.draw = () => {
    random.setSeed(seedValue);
    p.background(bgColor);
    p.fill(fgColor);
    p.stroke(fgColor);

    const width = p.width;
    const height = p.height;

    let xIterations = width / xCount;
    let yIterations = height / yCount;

    p.push();

    for (let i = 0; i < width; i += xIterations) {
      // draw lines from top to bottom
      const randomXLines1 = random.value();
      const randomXLines2 = random.value();
      p.strokeWeight(strokeWeight * width * 0.0025 * (0.5 + randomXLines1));

      const xVariationRandom1 = lerp(-xVariation, xVariation, randomXLines1);
      const xVariationRandom2 = lerp(-xVariation, xVariation, randomXLines2);
      p.line(i + xVariationRandom1, 0, width - i - xVariationRandom2, height);
    }

    for (let i = 0; i < width; i += yIterations) {
      // draw lines from left to right
      const randomYLines1 = random.value();
      const randomYLines2 = random.value();
      p.strokeWeight(strokeWeight * width * 0.0025 * (0.5 + randomYLines1));

      const yVariationRandom1 = lerp(-yVariation, yVariation, randomYLines1);
      const yVariationRandom2 = lerp(-yVariation, yVariation, randomYLines2);
      p.line(0, i + yVariationRandom1, width, height - i - yVariationRandom2);
    }
    p.pop();

    for (let i = 0; i < sphereAmount; i++) {
      const randomXpos = random.value();
      const randomYPos = random.value();
      const randomSphereSize = random.value();

      const positionX = lerp(0, width, randomXpos);
      const positionY = lerp(0, height, randomYPos);
      const sphereSize =
        lerp(minSphereSize, maxSphereSize, randomSphereSize) * width * 0.001;

      p.push();
      p.noStroke();
      p.ellipse(positionX, positionY, sphereSize, sphereSize);
      p.pop();
    }

    // radial thingie
    const originX = width / 2;
    const originY = height / 2;
    for (let i = 0; i < 360; i += 0.5) {
      p.push();
      p.translate(originX, originY);
      p.rotate(i);

      p.strokeWeight(20);
      p.line(0 + width * innerRadius, 0, width / 2, height / 2);
      p.pop();
    }
  };
};

export default sketch;
