import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Box, Flex } from "@/app/components/Primitives";
import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";

import { ImageCard } from "./components";

const MoreDesigns = ({ children }) => {
  return (
    <Box>
      <Flex justifyContent={"center"}>
        <Box width={1} bg={"lightGray"} py={3}>
          <Flex justifyContent={"center"}>{children}</Flex>
        </Box>
      </Flex>
    </Box>
  );
};

const getCollectionsData = collectionsData => {
  const collections = collectionsData.edges;

  return collections.map(collection => {
    const collectionData = collection.node;
    const { id, image, title } = collectionData;
    console.log(id, title);

    const imageSrc = image.originalSrc;
    const products = collectionData.products.edges.map(productData => {
      return productData.node.id;
    });

    return {
      id,
      image: imageSrc,
      products,
      title
    };
  });
};

const GalleryItem = styled(Box)``;

export const Gallery = ({ collections }) => {
  const getDesignThumbnails = data => {
    let collectionsData = getCollectionsData(data);

    return collectionsData.map(data => {
      const { id, image, title } = data;

      return (
        <GalleryItem width={[1, 0.5, 0.33]} pb={[3]} px={[2]} key={id}>
          <Link
            to={{
              pathname: `design/${id}`
            }}
          >
            <ImageCard path={image} title={title} />
          </Link>
        </GalleryItem>
      );
    });
  };

  return (
    <MaxWidthContainer>
      <Box ml={"-12px"} mr={"-12px"} mb={5}>
        <Flex flexWrap={"wrap"} justifyContent={"center"}>
          {getDesignThumbnails(collections)}
        </Flex>
      </Box>
      <MoreDesigns>More Artwork Coming Soon!</MoreDesigns>
    </MaxWidthContainer>
  );
};
