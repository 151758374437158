import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// local imports

import {
  SITE_GRAPHQL_ENDPOINT,
  SHOPIFY_STOREFRONT_ACCESS_TOKEN
} from "./config/config";
import theme from "./app/styles/theme";
import "./app/app.css";
import App from "./app/App";
import {
  About,
  CustomizeYourDesign,
  DesignCollection,
  NoMatch,
  Shop,
  ShippingAndReturns,
  Playground
} from "./app/sections";

import * as AppContext from "./app/App.context";
// import NotificationBar from "./app/components/NotificationBar";

const httpLink = createHttpLink({
  uri: SITE_GRAPHQL_ENDPOINT
});

const middlewareLink = setContext(() => ({
  headers: {
    "X-Shopify-Storefront-Access-Token": SHOPIFY_STOREFRONT_ACCESS_TOKEN
  }
}));

const client = new ApolloClient({
  link: middlewareLink.concat(httpLink),
  cache: new InMemoryCache()
});

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: ["'Karla'"].join(",")
  }
});

const AppContainer = () => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <StyledComponentThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <AppContext.Provider>
            {/* <NotificationBar /> */}
            <Router>
              <Switch>
                <Route exact path="/" component={App} />
                <Route path="/design/:designId" component={DesignCollection} />
                <Route
                  path="/customize/:designId"
                  component={CustomizeYourDesign}
                />
                <Route path="/about/" component={About} />
                <Route path="/shop/" component={Shop} />
                <Route path="/playground/" component={Playground} />
                <Route
                  path="/shipping-and-returns/"
                  component={ShippingAndReturns}
                />
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </Router>
          </AppContext.Provider>
        </ApolloProvider>
      </StyledComponentThemeProvider>
    </MuiThemeProvider>
  );
};

ReactDOM.render(<AppContainer />, document.getElementById("root"));
