import React from "react";

import { Box, Flex } from "@/app/components";
import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";

import InfoCard from "./InfoCard";
import { ShortDescriptionText } from "../ShortDescriptionText";

import MockupA from "@/app/images/mockup-pillow.png";
import MockupB from "@/app/images/mockup-tote-bag.png";
import MockupC from "@/app/images/mockup-mug.png";

export class InfoSection extends React.Component {
  render() {
    return (
      <MaxWidthContainer>
        <Box>
          <ShortDescriptionText>
            Infinitely varied unique procedural art <br />
            printed on your favorite things.
          </ShortDescriptionText>
        </Box>
        <Flex
          justifyContent={"space-between"}
          flexDirection={["column", "column", "row", "row"]}
        >
          <InfoCard
            title={"Procedural"}
            description="All the artwork here are created programmatically with the use of a computational algorithm."
            path={MockupA}
            first
          />
          <InfoCard
            title={"Personalized"}
            description="Use the parameters provided to personalize the artwork to your taste."
            path={MockupB}
            last
          />
          <InfoCard
            title={"Unique"}
            description="All the artwork could be randomized further with a seed value that would make them unique for you."
            path={MockupC}
          />
        </Flex>
      </MaxWidthContainer>
    );
  }
}
