import React from "react";
import P5Wrapper from "react-p5-wrapper";

import { CanvasContainer } from "./index.components";

const DesignDisplay = props => {
  const defaultSize = 1024;
  const defaultSizeUnit = "px";
  const defaultAspectRatio = {
    x: 1,
    y: 1
  };

  const {
    configuration,
    sketch,
    state,
    width = defaultSize,
    height = defaultSize,
    aspectRatio = defaultAspectRatio,
    extraState
  } = props;

  const calculatedWidth = width * aspectRatio.x;
  const calculatedWidthUnit = `${calculatedWidth}${defaultSizeUnit}`;

  const calculatedHeight = height * aspectRatio.y;
  const calculatedHeightUnit = `${calculatedHeight}${defaultSizeUnit}`;

  const additionalState = {
    screenSizeX: calculatedWidth,
    screenSizeY: calculatedHeight,
    pixelDensity: 0.75,
    ...extraState,
    configuration
  };

  document.p5jsState = additionalState;

  const newState = {
    ...state,
    ...additionalState
  };

  return (
    <CanvasContainer width={calculatedWidthUnit} height={calculatedHeightUnit}>
      <P5Wrapper sketch={sketch} {...newState} />
    </CanvasContainer>
  );
};

export default DesignDisplay;
