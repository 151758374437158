export const exists = prop => {
  return prop !== undefined;
};

export const getValueIfUndefined = (obj, property, value) => {
  return obj[property] !== undefined ? obj[property] : value;
};

export const getConfigValueIfUndefined = (
  fallbackConfig,
  initialConfig,
  property
) => {
  const desiredConfigItem =
    initialConfig &&
    initialConfig.find(item => {
      return item.name === property;
    });

  if (desiredConfigItem) {
    return desiredConfigItem.defaultValue;
  }

  const configItem = fallbackConfig.find(item => {
    return item.name === property;
  });

  return configItem.defaultValue;
};
