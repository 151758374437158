import React, { useState, useEffect } from "react";
import marked from "marked";
import styled from "styled-components";
import { Fade } from "@/app/components/Fade";

import ContentFile from "./content.md";

import { Box, Text } from "@/app/components";
import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";
import theme from "@/app/styles/theme";
import { LayoutWrapper } from "@/app/components/LayoutWrapper";

const MainTitle = ({ children }) => {
  return (
    <Text fontSize={3} textAlign={"left"} fontWeight={"900"}>
      {children}
    </Text>
  );
};

const MarkdownContainer = styled(Box)`
  h2 {
    font-size: ${theme.fontSizes["4"]};
    padding-bottom: ${theme.space[5]}px;
    padding-top: ${theme.space[5]}px;
    font-weight: ${theme.fontWeights[5]};
  }

  h3 {
    padding-bottom: ${theme.space[4]}px;
  }

  p,
  ul {
    color: ${theme.colors.gray};
    font-size: ${theme.fontSizes["6"]};
    font-family: ${theme.fonts.body};
    line-height: ${theme.lineHeights.copy};
    padding-bottom: ${theme.space[4]}px;
  }

  ul {
    padding-left: 40px;
  }

  li {
    list-style-type: square;
    padding-left: 5px;
  }
`;

const createMarkup = content => {
  return { __html: content };
};

const ShippingAndReturns = () => {
  const [bodyText, setBodyText] = useState("");

  useEffect(() => {
    fetch(ContentFile)
      .then(response => response.text())
      .then(text => {
        setBodyText(marked(text));
      });
  }, [ContentFile]);

  return (
    <LayoutWrapper>
      <Fade>
        <Box px={[10, 20]} py={[10, 20]} pb={10}>
          <MaxWidthContainer width={"720px"}>
            <Box pb={0}>
              <MainTitle>Shipping &amp; Returns</MainTitle>
            </Box>
            <MarkdownContainer
              dangerouslySetInnerHTML={createMarkup(bodyText)}
            />
          </MaxWidthContainer>
        </Box>
      </Fade>
    </LayoutWrapper>
  );
};

export default ShippingAndReturns;
