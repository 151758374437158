import React from "react";

import { Box, Flex } from "@/app/components";
import { Product } from "../Product";

export const SelectProduct = ({
  children,
  displayChildren,
  onClick,
  products,
  selectedProductId
}) => {
  const getProductItems = () =>
    products.map(productItem => {
      const { id, image, options, priceRange, title } = productItem;
      const selected = selectedProductId === id;

      return (
        <Product
          imagePath={image}
          onClick={() => {
            onClick(id);
          }}
          selected={selected}
          key={id}
          title={title}
          options={options}
          priceRange={priceRange}
        />
      );
    });

  return (
    <Flex flexDirection={["column", "column", "row"]}>
      {getProductItems()}
      {displayChildren && <Box>{children}</Box>}
    </Flex>
  );
};
