import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Box } from "@/app/components";

const styles = _theme => ({
  spinner: {
    color: "#6798e5"
  }
});

const LoadingIconContainer = styled(Box)`
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${({ height }) => {
    if (height === undefined) {
      return `height: 100vh;`;
    } else {
      return `height: ${height}`;
    }
  }}
`;

class LoadingIcon extends React.Component {
  render() {
    const { classes, height } = this.props;

    return (
      <LoadingIconContainer height={height}>
        <CircularProgress size={60} className={classes.spinner} />
      </LoadingIconContainer>
    );
  }
}

export default withStyles(styles)(LoadingIcon);
