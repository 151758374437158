import React from "react";
import { useLocation } from "react-router-dom";

import Layout from "@/app/components/Layout";
import * as AppContext from "@/app/App.context";

const getParsedPathname = pathname => {
  if (pathname === "/") {
    return "";
  }

  if (pathname.includes("/shop")) {
    return "Shop";
  }

  if (pathname.includes("/about")) {
    return "About";
  }

  if (pathname.includes("/design")) {
    return "Design";
  }

  if (pathname.includes("/customize")) {
    return "Customize";
  }
};

export const LayoutWrapper = ({ children }) => {
  const location = useLocation();
  const title = getParsedPathname(location.pathname);

  return (
    <AppContext.Consumer>
      {appContext => {
        const {
          checkout,
          removeLineItemInCart,
          updateLineItemInCart
        } = appContext;

        const layoutProps = {
          checkout,
          title,
          removeLineItemInCart,
          updateLineItemInCart
        };

        return (
          <Layout {...layoutProps}>
            {() => {
              return children;
            }}
          </Layout>
        );
      }}
    </AppContext.Consumer>
  );
};
