import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ShoppingCart } from "react-feather";

import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";
import { Box, Flex, Text } from "@/app/components/Primitives";
import { LogoIcon } from "@/app/components/LogoIcon";
import { border } from "@/app/styles/mixins";

const HeaderContainer = styled(Box)`
  border-bottom: ${border};
`;

const HeaderLogoContainer = styled(Box)``;

const CartButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: black;
  padding-top: 0;
  padding-bottom: 0;
`;

const CartContainer = styled(Box)`
  position: relative;
`;

const CartNotification = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #0969ff;
  position: absolute;
  right: 0;
  top: 0;
`;

const HeaderLink = ({ path, text }) => {
  return (
    <Box mr={8} mb={[1, 0]}>
      <Link to={path}>
        <Flex alignItems={"center"}>
          <Text fontSize={5} fontWeight={"300"}>
            {text}
          </Text>
        </Flex>
      </Link>
    </Box>
  );
};

class Header extends React.Component {
  render() {
    const {
      cartHasItems,
      isCartOpen,
      onCartOpen
      // onOpenCustomerAuth
    } = this.props;
    return (
      <HeaderContainer py={6} px={10}>
        <MaxWidthContainer>
          {/* <HeaderNav>
          <Link to="/">
            <Flex alignItems={"center"}>
              <Text fontSize={5} fontWeight={"700"}>
                About
              </Text>
            </Flex>
          </Link> */}
          {/* <HeaderNavItem
            className="button App__customer-actions"
            onClick={onOpenCustomerAuth}
            data-customer-type="new-customer"
          >
            Create an Account
          </HeaderNavItem>
          <HeaderNavItem
            className="login App__customer-actions"
            onClick={onOpenCustomerAuth}
          >
            Log in
          </HeaderNavItem> */}

          {/* </HeaderNav> */}

          <Flex justifyContent={"space-between"} alignItems={["top", "center"]}>
            <Flex
              alignItems={["left", "center"]}
              flexDirection={["column", "row"]}
            >
              <HeaderLogoContainer mr={10} mb={[1, 0]}>
                <Link to="/">
                  <Flex
                    alignItems={"center"}
                    flexDirection={["row-reverse", "row"]}
                  >
                    <Box mr={[0, 2]} ml={[2, 0]}>
                      <LogoIcon />
                    </Box>
                    <Text fontSize={5} fontWeight={"700"}>
                      ProceduralArt
                    </Text>
                  </Flex>
                </Link>
              </HeaderLogoContainer>
              <Flex flexDirection={["column", "row"]}>
                <HeaderLink path={"/shop"} text={"Shop"} />
                <HeaderLink path={"/about"} text={"About"} />
              </Flex>
            </Flex>

            <Box>
              {!isCartOpen && (
                <CartContainer>
                  {cartHasItems && <CartNotification />}
                  <CartButton onClick={onCartOpen}>
                    <ShoppingCart size={"20px"} />
                  </CartButton>
                </CartContainer>
              )}
            </Box>
          </Flex>
        </MaxWidthContainer>
      </HeaderContainer>
    );
  }
}

export default Header;
