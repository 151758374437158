import { random } from "canvas-sketch-util";
import { lerp } from "canvas-sketch-util/math";
import colors from "nice-color-palettes";

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined
} from "../../utilities/misc";

export const config = [
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 2173,
    label: "Seed Value"
  },
  {
    name: "count",
    type: "scalar",
    min: 1,
    max: 60,
    defaultValue: 32,
    label: "Count"
  },
  {
    name: "innerRadiusMult",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.044,
    label: "Inner Radius"
  },
  {
    name: "outerRadiusMult",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.319,
    label: "Outer Radius"
  },
  {
    name: "minLength",
    type: "scalar",
    min: 1,
    max: 360,
    defaultValue: 26,
    label: "Min Length"
  },
  {
    name: "maxLength",
    type: "scalar",
    min: 1,
    max: 360,
    defaultValue: 268,
    label: "Max Length"
  },
  {
    name: "minWidthMultiplier",
    type: "float",
    min: 0,
    max: 0.1,
    defaultValue: 0.005,
    label: "Min Width Multiplier"
  },
  {
    name: "maxWidthMultiplier",
    type: "float",
    min: 0,
    max: 0.2,
    defaultValue: 0.054,
    label: "Max Width Multiplier"
  },
  {
    name: "colorTheme",
    type: "scalar",
    min: 1,
    max: 99,
    defaultValue: 50,
    label: "Color Theme"
  },
  {
    name: "colorAmount",
    type: "scalar",
    min: 1,
    max: 5,
    defaultValue: 5,
    label: "Color Amount"
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#ececec",
    label: "Background Color"
  }
];

const sketch = p => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let seedValue = getConfigValueIfUndefined(
    config,
    configFromState,
    "seedValue"
  );
  let count = getConfigValueIfUndefined(config, configFromState, "count");
  let innerRadiusMult = getConfigValueIfUndefined(
    config,
    configFromState,
    "innerRadiusMult"
  );
  let outerRadiusMult = getConfigValueIfUndefined(
    config,
    configFromState,
    "outerRadiusMult"
  );
  let minLength = getConfigValueIfUndefined(
    config,
    configFromState,
    "minLength"
  );
  let maxLength = getConfigValueIfUndefined(
    config,
    configFromState,
    "maxLength"
  );
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");

  let minWidthMultiplier = getConfigValueIfUndefined(
    config,
    configFromState,
    "minWidthMultiplier"
  );
  let maxWidthMultiplier = getConfigValueIfUndefined(
    config,
    configFromState,
    "maxWidthMultiplier"
  );

  let colorAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorAmount"
  );
  let colorTheme = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorTheme"
  );

  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = props => {
    if (exists(props.seedValue)) {
      seedValue = parseInt(props.seedValue, 10);
    }

    if (exists(props.count)) {
      count = parseInt(props.count, 10);
    }

    if (exists(props.innerRadiusMult)) {
      innerRadiusMult = parseFloat(props.innerRadiusMult);
    }

    if (exists(props.outerRadiusMult)) {
      outerRadiusMult = parseFloat(props.outerRadiusMult);
    }

    if (exists(props.minLength)) {
      minLength = parseInt(props.minLength, 10);
    }

    if (exists(props.maxLength)) {
      maxLength = parseInt(props.maxLength, 10);
    }

    if (exists(props.minWidthMultiplier)) {
      minWidthMultiplier = parseFloat(props.minWidthMultiplier);
    }

    if (exists(props.maxWidthMultiplier)) {
      maxWidthMultiplier = parseFloat(props.maxWidthMultiplier);
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }

    if (exists(props.colorTheme)) {
      colorTheme = parseInt(props.colorTheme, 10);
    }

    if (exists(props.colorAmount)) {
      colorAmount = parseInt(props.colorAmount, 10);
    }
  };

  p.setup = () => {
    p.createCanvas(1024, 1024);
    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(6);
    p.pixelDensity(pixelDensity);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    random.setSeed(seedValue);
    p.background(bgColor);
    p.noStroke();

    const width = p.width;
    const height = p.height;

    const minRadius = width * innerRadiusMult;
    const maxRadius = width * outerRadiusMult;

    const minThickness = width * minWidthMultiplier;
    const maxThickness = width * maxWidthMultiplier;

    const colorPalette = colors[colorTheme];

    for (let j = 0; j < count; j++) {
      const radius = lerp(minRadius, maxRadius, random.value());
      const length = lerp(minLength, maxLength, random.value());
      const thickness = lerp(minThickness, maxThickness, random.value());
      const color = random.pick(colorPalette.slice(0, colorAmount));

      const rotation = random.value() * 360;
      const rectWidth = width * 0.02;

      ring({
        color,
        length,
        p,
        j,
        rectWidth,
        radius,
        rotation,
        x: width / 2,
        y: height / 2,
        thickness
      });
    }
  };
};

function ring({
  color,
  length,
  p,
  radius,
  x,
  y,
  thickness,
  rectWidth,
  rotation
}) {
  for (let i = 0; i < length; i++) {
    p.push();
    p.fill(color);
    p.translate(x, y);
    p.rotate(rotation + i);
    p.rect(radius, 0, thickness, rectWidth);
    p.pop();
  }
}

export default sketch;
