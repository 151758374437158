import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";

import { Box, Flex } from "@/app/components";
import URLUpdateProvider from "@/app/components/URLUpdateProvider";
import { getBreakPoint } from "@/app/styles/theme";

import DesignController from "../DesignController";
import DesignDisplay from "../DesignDisplay";

const DesignDisplayContainer = styled(Box)``;
const DesignControllerContainer = styled(Box)``;

class Design extends React.Component {
  state = {};

  render() {
    const {
      children,
      configuration,
      fullHeightController = true,
      sketch,
      sketchProportions
    } = this.props;
    const mobileBreakpoint = getBreakPoint("mobile");

    return (
      <MediaQuery minWidth={mobileBreakpoint}>
        {matches => {
          return (
            <URLUpdateProvider>
              {(updateURL, queryObj) => {
                const parseKey = "state";

                const getDesignStateFromQuery = () => {
                  return queryObj && queryObj[parseKey]
                    ? JSON.parse(queryObj[parseKey])
                    : null;
                };
                const initialState = getDesignStateFromQuery(queryObj);

                return (
                  <Flex flexDirection={"column"}>
                    <Box pb={4}>{!matches && children}</Box>
                    <Flex
                      width={"100%"}
                      flexDirection={["column", "row", "row", "row"]}
                    >
                      <DesignDisplayContainer width={[1, 0.5, 0.6]}>
                        <Box mb={4}>
                          <DesignDisplay
                            sketch={sketch}
                            state={getDesignStateFromQuery(queryObj)}
                            aspectRatio={sketchProportions}
                            configuration={configuration}
                          />
                        </Box>
                        {matches && children}
                      </DesignDisplayContainer>
                      <DesignControllerContainer
                        pl={[0, 10]}
                        width={[1, 0.5, 0.4]}
                      >
                        <DesignController
                          getState={value => {
                            updateURL([[parseKey, JSON.stringify(value)]]);
                          }}
                          initialState={initialState}
                          controller={configuration}
                          mainPage={!fullHeightController}
                        />
                      </DesignControllerContainer>
                    </Flex>
                  </Flex>
                );
              }}
            </URLUpdateProvider>
          );
        }}
      </MediaQuery>
    );
  }
}

export default Design;
