import React from "react";
import { Fade } from "@/app/components/Fade";

import { Box } from "@/app/components/Primitives";
import { LayoutWrapper } from "@/app/components/LayoutWrapper";
import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";

import { HeroSection } from "./components/HeroSection";
import { InfoSection } from "./components/InfoSection";
import { ShortDescriptionText } from "./components/ShortDescriptionText";

import { Gallery } from "@/app/sections/Gallery";

export class LandingPage extends React.Component {
  state = {};

  updateState = state => {
    this.setState({
      ...state
    });
  };

  render() {
    return (
      <LayoutWrapper>
        <Box>
          <Fade>
            <Box pb={10}>
              <HeroSection state={this.state} getState={this.updateState} />
            </Box>
          </Fade>

          <Fade fraction={0.1}>
            <Box pb={20} px={[10]}>
              <InfoSection />
            </Box>
          </Fade>

          <Fade fraction={0.05}>
            <Box pb={20} px={[10]}>
              <MaxWidthContainer>
                <ShortDescriptionText>
                  Choose an artwork and start personalizing.
                </ShortDescriptionText>
                <Gallery />
              </MaxWidthContainer>
            </Box>
          </Fade>
        </Box>
      </LayoutWrapper>
    );
  }
}
