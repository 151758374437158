import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Fade } from "@/app/components/Fade";

import { Box, Flex, Text } from "@/app/components";
import { Button } from "@/app/components/Button";
import DesignWithController from "@/app/components/DesignWithController";
import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";

import { getDesignConfiguration, getProductProportions } from "./utils";

const CustomizeYourDesign = ({
  addVariantToCart,
  collectionId,
  data,
  designSettings,
  handleCartOpen,
  history,
  location,
  selectedProductOptions
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { config: configuration, sketch } = getDesignConfiguration(
    collectionId
  );

  const variantId = data.node.variantBySelectedOptions.id;
  const productProportions = getProductProportions(selectedProductOptions);

  const handleAddToCart = () => {
    addVariantToCart({
      variantId,
      customAttributes: JSON.stringify(designSettings)
    });
    handleCartOpen();
  };

  const handlePrevious = () => {
    history.push(`/design/${collectionId}${location.search}`);
  };

  return (
    <Box px={[10]} pt={10} mb={20} pb={20}>
      <MaxWidthContainer>
        <Box>
          <Text fontSize={3} fontWeight={"900"} mb={5}>
            Step 2: Customize the Design
          </Text>
          <Fade>
            <Box>
              <DesignWithController
                configuration={configuration}
                sketch={sketch}
                sketchProportions={productProportions}
              >
                <Flex flexDirection={["column", "column", "row"]}>
                  <Box mr={[0, 0, 2]} width={1} mb={[2, 2, 0]}>
                    <Button fullWidth onClick={handlePrevious}>
                      Previous
                    </Button>
                  </Box>
                  <Box ml={[0, 0, 2]} width={1}>
                    <Button fullWidth onClick={handleAddToCart} type="pink">
                      Add to Cart
                    </Button>
                  </Box>
                </Flex>
              </DesignWithController>
            </Box>
          </Fade>
        </Box>
      </MaxWidthContainer>
    </Box>
  );
};

export default withRouter(CustomizeYourDesign);
