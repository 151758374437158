import React from "react";
import { Fade } from "@/app/components/Fade";

import { Box } from "@/app/components";

import { LayoutWrapper } from "@/app/components/LayoutWrapper";

import { Gallery } from "@/app/sections/Gallery";

export class Shop extends React.Component {
  render() {
    return (
      <LayoutWrapper>
        <Box px={10} py={20} pb={10}>
          <Fade>
            <Gallery />
          </Fade>
        </Box>
      </LayoutWrapper>
    );
  }
}
