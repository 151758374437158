import { random } from "canvas-sketch-util";
import { lerp } from "canvas-sketch-util/math";

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined
} from "../../utilities/misc";
import tinyColor from "../../utilities/tinycolor";

export const config = [
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 0,
    label: "Seed Value"
  },
  {
    name: "count",
    type: "scalar",
    min: 1,
    max: 20,
    defaultValue: 12,
    label: "Count"
  },
  {
    name: "frequency",
    type: "scalar",
    min: 0,
    max: 15,
    defaultValue: 6,
    label: "Frequency"
  },
  {
    name: "amplitude",
    type: "scalar",
    min: 0,
    max: 500,
    defaultValue: 225,
    label: "Amplitude"
  },
  {
    name: "colorAdjustmentIncrement",
    type: "scalar",
    min: 1,
    max: 100,
    defaultValue: 57,
    label: "Color Adjustment Increment"
  },
  {
    name: "darken",
    type: "boolean",
    defaultValue: true,
    label: "Darken"
  },
  {
    name: "color",
    type: "color",
    defaultValue: "#ef4b95",
    label: "Color"
  }
];

const sketch = p => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let seedValue = getConfigValueIfUndefined(
    config,
    configFromState,
    "seedValue"
  );
  let count = getConfigValueIfUndefined(config, configFromState, "count");
  let frequency = getConfigValueIfUndefined(
    config,
    configFromState,
    "frequency"
  );
  let amplitude = getConfigValueIfUndefined(
    config,
    configFromState,
    "amplitude"
  );

  let colorAdjustmentIncrement = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorAdjustmentIncrement"
  );
  let darken = getConfigValueIfUndefined(config, configFromState, "darken");
  let color = getConfigValueIfUndefined(config, configFromState, "color");

  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = props => {
    if (exists(props.seedValue)) {
      seedValue = parseInt(props.seedValue, 10);
    }

    if (exists(props.count)) {
      count = parseInt(props.count, 10);
    }

    if (exists(props.frequency)) {
      frequency = parseFloat(props.frequency);
    }

    if (exists(props.amplitude)) {
      amplitude = parseInt(props.amplitude, 10);
    }

    if (exists(props.colorAdjustmentIncrement)) {
      colorAdjustmentIncrement = parseInt(props.colorAdjustmentIncrement, 10);
    }

    if (exists(props.darken)) {
      darken = props.darken;
    }

    if (exists(props.color)) {
      color = props.color;
    }
  };

  p.setup = () => {
    p.createCanvas(1024, 1024);
    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(3);
    p.pixelDensity(pixelDensity);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    random.setSeed(seedValue);
    p.background(color);

    const width = p.width;
    const height = p.height;
    const lineWidth = width * 0.1;

    p.stroke(color);

    let gap = height / count;

    for (let i = 0; i < height; i += gap) {
      p.strokeWeight(lineWidth);

      let colorAdjustmentAmount = lerp(0, colorAdjustmentIncrement, i / height);
      let newColor;
      if (darken) {
        newColor = tinyColor(color).darken(colorAdjustmentAmount);
      } else {
        newColor = tinyColor(color).brighten(colorAdjustmentAmount);
      }
      newColor = newColor.toRgbString();
      p.stroke(newColor);

      for (let j = 0; j < width; j += 15) {
        let yPos =
          random.noise1D(j + i * 50, frequency * 0.0001, amplitude) + i;
        p.line(j, height, j, yPos);
      }
    }
  };
};

export default sketch;
