import React from "react";

import { Box, Text } from "@/app/components";
import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";

import { LayoutWrapper } from "@/app/components/LayoutWrapper";
import DesignWithController from "@/app/components/DesignWithController";

import { accurateQuilt as sketch } from "@/app/artwork";

let newConfiguration = [];
newConfiguration = newConfiguration.concat([
  {
    name: "text",
    type: "text",
    defaultValue: "404",
    label: "Text"
  },
  {
    name: "spacingMultiplier",
    type: "float",
    min: 0,
    max: 10,
    defaultValue: 0.1,
    label: "Spacing Multiplier"
  },
  {
    name: "textSize",
    type: "float",
    min: 200,
    max: 1000,
    defaultValue: 400,
    label: "Text Size"
  },
  {
    name: "textCopyAmount",
    type: "scalar",
    min: 0,
    max: 80,
    defaultValue: 20,
    label: "Text Copy Amount"
  }
]);

const NoMatch = () => {
  return (
    <LayoutWrapper>
      <MaxWidthContainer>
        <Box px={[10, 20]} py={[10, 20]} pb={10}>
          <Text fontSize={3} fontWeight={"900"} mb={5}>
            Page Not Found
          </Text>
          <Box>
            <DesignWithController
              configuration={newConfiguration}
              sketch={sketch}
            ></DesignWithController>
          </Box>
        </Box>
      </MaxWidthContainer>
    </LayoutWrapper>
  );
};

export default NoMatch;
