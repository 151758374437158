import styled from "styled-components";
import { Box } from "@/app/components/Primitives";
import { boxShadow } from "@/app/styles/mixins";

export const DesignControllerContainer = styled(Box)`
  box-shadow: ${boxShadow};
`;

export const HeroContainer = styled(Box)`
  background-blend-mode: hard-light;
  background-color: #ffffff94;
  background-image: linear-gradient(#c3c3c3, #fff);
`;
