import designConfigurations, { idToName } from "./data/designConfigurations";

export const getDesignConfiguration = variantId => {
  const designName = idToName[variantId];
  const configuration = designConfigurations[designName];

  return configuration;
};

export function getProductProportions(productOptions) {
  const proportions = {
    x: 1,
    y: 1
  };

  const sizeItem = productOptions.find(item => {
    return item.name === "Size";
  });

  if (!sizeItem) {
    return proportions;
  }

  // if the size has 'oz' in it, it is a mug
  // if it has `×` in it (notice not `x`) it is a frame.
  const frameIndicator = "×";
  const mugIndicator = "oz";

  const sizeValue = sizeItem.value;
  if (sizeValue.includes(frameIndicator)) {
    // the sizes are for a frame
    const sizeValueArr = sizeValue.split(frameIndicator);

    const sizeX = parseInt(sizeValueArr[0], 10);
    const sizeY = parseInt(sizeValueArr[1], 10);

    const biggerSide = Math.max(sizeX, sizeY);

    const normalizedSizeX = sizeX / biggerSide;
    const normalizedSizeY = sizeY / biggerSide;

    proportions.x = normalizedSizeX;
    proportions.y = normalizedSizeY;
  } else if (sizeValue.includes(mugIndicator)) {
    // the sizes are for a mug
    const size = parseInt(sizeValue, 10);
    let x = 1;
    let y = 1;

    if (size === 11) {
      x = 1;
      y = 0.39;
    } else if (size === 15) {
      x = 1;
      y = 0.42;
    }

    proportions.x = x;
    proportions.y = y;
  }

  return proportions;
}
