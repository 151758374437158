import React, { useState } from "react";
import styled from "styled-components";

import { Box, Flex, Text } from "@/app/components";
import LoadingIcon from "@/app/components/LoadingIcon";

import { border, borderSelected, boxShadow } from "@/app/styles/mixins";

const Container = styled(Box)`
  border: ${border()};
  box-shadow: ${boxShadow()};
  cursor: pointer;

  ${({ selected, onClick }) => {
    return `
        border: ${selected && borderSelected()};
        cursor: ${onClick ? "pointer" : "initial"}
      `;
  }};
`;

export const Product = ({
  imagePath,
  onClick,
  priceRange,
  price,
  selected,
  title
}) => {
  const [loading, setLoading] = useState(true);

  let productPrice = null;
  if (!price && priceRange) {
    const minAmount = priceRange.min.amount;
    const maxAmount = priceRange.max.amount;
    productPrice =
      minAmount === maxAmount
        ? `${minAmount}$`
        : `${minAmount}$ - ${maxAmount}$`;
  } else {
    productPrice = `${price}$`;
  }

  const handleOnLoad = () => {
    setLoading(false);
  };

  return (
    <Container
      width={1}
      p={2}
      mr={5}
      selected={selected}
      onClick={onClick}
      mb={[5, 5, 0]}
    >
      {loading && <LoadingIcon height={"200px"} />}
      <img
        src={imagePath}
        alt={title}
        onLoad={handleOnLoad}
        style={{
          display: loading ? "none" : "block"
        }}
      />
      <Box pt={4} pl={1} pr={1}>
        <Flex justifyContent={"space-between"}>
          <Text>{title}</Text>
          <Text fontWeight={"600"}>{productPrice}</Text>
        </Flex>
      </Box>
    </Container>
  );
};
