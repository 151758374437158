import React from "react";
import Select from "@material-ui/core/Select";

import { Box, Text } from "@/app/components";
import { MenuItem } from "@material-ui/core";

export const singleVariantOptionOverride = { old: "Title", new: "Option" };
export const singleVariantLabelOverride = {
  old: "Default Title",
  new: "Default Option"
};

export class SelectedProductParameters extends React.Component {
  render = () => {
    const { onChange, allOptions, selectionState } = this.props;

    const selectFields =
      allOptions &&
      allOptions.map((option, index, arr) => {
        const isLast = index === arr.length - 1;
        const optionName = option.name;

        const optionValues = option.values.map(optionValue => {
          return {
            value: optionValue,
            label: optionValue
          };
        });

        let selectedValue = selectionState[optionName];
        selectedValue = selectedValue === undefined ? "" : selectedValue;

        return (
          <Box mr={isLast ? 0 : 5} key={optionName} width={1} mb={3}>
            <Text mb={2} fontWeight={"700"} fontSize={6}>
              Select{" "}
              {optionName === singleVariantOptionOverride.old
                ? singleVariantOptionOverride.new
                : optionName}
            </Text>
            <Box fontFamily={"body"}>
              <Select
                value={selectedValue}
                onChange={event => {
                  const selectedValue = event.target.value;
                  onChange(optionName, selectedValue);
                }}
              >
                {optionValues.map(option => {
                  const { label, value } = option;

                  return (
                    <MenuItem value={value} key={value}>
                      {label === singleVariantLabelOverride.old
                        ? singleVariantLabelOverride.new
                        : label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
        );
      });

    return selectFields || null;
  };
}
