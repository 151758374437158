import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined
} from "../../utilities/misc";

export const config = [
  {
    name: "text",
    type: "text",
    defaultValue: "COOL",
    label: "Text"
  },
  {
    name: "textSize",
    type: "float",
    min: 25,
    max: 1200,
    defaultValue: 187,
    label: "Text Size"
  },
  {
    name: "multiplier",
    type: "float",
    min: 1.05,
    max: 2,
    defaultValue: 1.182,
    label: "Separation Multiplier"
  },
  {
    name: "defaultWidthMultiplier",
    type: "float",
    min: 0.01,
    max: 5,
    defaultValue: 1.393,
    label: "Width Multiplier"
  },
  {
    name: "colorFadeMultiplier",
    type: "float",
    min: 0.05,
    max: 2,
    defaultValue: 0.451,
    label: "Color Fade Multiplier"
  }
];

const sketch = p => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let text = getConfigValueIfUndefined(config, configFromState, "text");
  let textSize = getConfigValueIfUndefined(config, configFromState, "textSize");
  let multiplier = getConfigValueIfUndefined(
    config,
    configFromState,
    "multiplier"
  );
  let defaultWidthMultiplier = getConfigValueIfUndefined(
    config,
    configFromState,
    "defaultWidthMultiplier"
  );
  let colorFadeMultiplier = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorFadeMultiplier"
  );

  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = props => {
    if (exists(props.text)) {
      text = props.text;
    }

    if (exists(props.textSize)) {
      textSize = parseFloat(props.textSize);
    }

    if (exists(props.multiplier)) {
      multiplier = parseFloat(props.multiplier);
    }

    if (exists(props.defaultWidthMultiplier)) {
      defaultWidthMultiplier = parseFloat(props.defaultWidthMultiplier);
    }

    if (exists(props.colorFadeMultiplier)) {
      colorFadeMultiplier = parseFloat(props.colorFadeMultiplier);
    }
  };

  p.setup = () => {
    p.createCanvas(1024, 1024);
    p.frameRate(6);
    p.pixelDensity(pixelDensity);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    p.background("black");

    let screenWidth = p.width;
    let screenHeight = p.height;
    const originX = screenWidth / 2;
    const originY = screenHeight / 2;

    let separation = 0.4;
    let defaultWidth = screenWidth * 0.01 * defaultWidthMultiplier;
    let defaultStrokeWeight = 1;

    p.strokeWeight(defaultStrokeWeight);
    p.fill(255);

    p.textSize(textSize);
    p.textFont("Helvetica");
    p.text(text, originX, originY);

    for (let i = 0; i < screenWidth; i += separation) {
      p.rect(i, 0, defaultWidth, screenHeight);
      separation *= multiplier;
      p.fill(255 - i * colorFadeMultiplier);
    }
  };
};

export default sketch;
