import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash/debounce";

import { ChromePicker } from "react-color";
import ColorPalette from "./ColorPalette";

import {
  ControllerContainer,
  SlidersContainer,
  SliderContainer,
  SliderLabel
} from "./index.components";

import { Slider } from "./Slider";

class DesignController extends React.Component {
  state = {};

  static getDerivedStateFromProps = (props, state) => {
    // want to run this only if the internal state is empty
    if (Object.keys(state).length !== 0) {
      return null;
    }

    const { controller, initialState } = props;
    const newState = {};

    if (initialState && Object.keys(initialState).length > 0) {
      Object.keys(initialState).forEach(key => {
        const value = initialState[key];
        newState[key] = value;
      });
    } else {
      Object.keys(controller).forEach(index => {
        const itemName = controller[index].name;
        const itemValue = controller[index].default;

        newState[itemName] = itemValue;
      });
    }

    // update the top level state.
    props.getState(newState);

    return newState;
  };

  handleChange = (e, value, key) => {
    const { getState } = this.props;
    this.setState(
      {
        [key]: value
      },
      () => {
        if (getState) {
          getState(this.state);
        }
      }
    );
  };

  getControllers = () => {
    const { controller } = this.props;

    if (!controller) {
      return null;
    }

    const controllers = controller.map((data, index) => {
      const { defaultValue, name, label, min, max, type } = data;

      if (defaultValue === undefined) {
        throw new Error(
          `no default value defined for parameter controller ${label}`
        );
      }

      const controllerValue =
        this.state[name] === undefined ? defaultValue : this.state[name];

      const onChangeCallback = (e, value) => this.handleChange(e, value, name);

      const controllerProps = {
        value: controllerValue,
        onChange: debounce(onChangeCallback, 30)
      };

      let controllerComponent;

      switch (type) {
        case "scalar":
          controllerProps.step = 1;
          controllerProps.min = min;
          controllerProps.max = max;
          controllerComponent = <Slider {...controllerProps} />;
          break;
        case "float":
          controllerProps.min = min;
          controllerProps.max = max;
          controllerProps.step = 0.01;
          controllerComponent = <Slider {...controllerProps} />;
          break;
        case "boolean":
          let checkboxProps = { ...controllerProps };
          delete checkboxProps.value;
          checkboxProps.checked = controllerValue;
          controllerComponent = <Checkbox {...checkboxProps} />;
          break;
        case "color":
          let colorComponentProps = {
            color: controllerProps.value,
            disableAlpha: true,
            onChange: color => this.handleChange(null, color.hex, name)
          };
          controllerComponent = <ChromePicker {...colorComponentProps} />;
          break;
        case "colorPalette":
          let colorPaletteProps = {
            colors: controllerProps.value,
            onChange: colors => this.handleChange(null, colors, name)
          };
          controllerComponent = <ColorPalette {...colorPaletteProps} />;
          break;
        case "text":
          let textProps = {
            ...controllerProps
          };
          textProps.onChange = e => this.handleChange(e, e.target.value, name);

          controllerComponent = <TextField variant="outlined" {...textProps} />;
          break;
        default:
          break;
      }

      return (
        <SliderContainer key={index}>
          <SliderLabel>{label}</SliderLabel>
          {controllerComponent}
        </SliderContainer>
      );
    });

    return controllers;
  };

  render() {
    return (
      <ControllerContainer>
        <SlidersContainer {...this.props}>
          {this.getControllers()}
        </SlidersContainer>
      </ControllerContainer>
    );
  }
}

export default DesignController;
