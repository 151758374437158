import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { withRouter } from "react-router-dom";

import { LayoutWrapper } from "@/app/components/LayoutWrapper";
import URLUpdateProvider from "@/app/components/URLUpdateProvider";
import LoadingIcon from "@/app/components/LoadingIcon";
import { getProductsData } from "./utils";

import DesignCollection from "./DesignCollection";
import { convertSelectedProductOptionsObjToArray } from "./DesignCollection";

export const parseProductOptionsQuery = query => {
  let variantSelectionPerProduct;
  // TODO think about this more!
  try {
    variantSelectionPerProduct = JSON.parse(query);
  } catch (e) {
    variantSelectionPerProduct = {};
  }

  return variantSelectionPerProduct;
};

class DesignCollectionProvider extends React.Component {
  render() {
    const collectionId = this.props.match.params.designId;

    return (
      <URLUpdateProvider>
        {(updateURL, queryObj) => {
          const selectedProductId = (queryObj && queryObj["productId"]) || "";
          const selectedVariantFromURL =
            (queryObj && queryObj["productOptions"]) || {};
          const variantSelectionPerProduct = parseProductOptionsQuery(
            selectedVariantFromURL
          );
          const variantSelectionForProduct =
            variantSelectionPerProduct[selectedProductId];
          const selectedProductOptionsArr =
            (variantSelectionForProduct &&
              convertSelectedProductOptionsObjToArray(
                variantSelectionForProduct
              )) ||
            [];

          return (
            <LayoutWrapper>
              <Query
                query={queryGetCollectionData}
                variables={{ id: collectionId }}
              >
                {({
                  loading: loadingCollectionData,
                  error: errorCollectionData,
                  data: collectionData
                }) => {
                  if (loadingCollectionData || !collectionData) {
                    return <LoadingIcon />;
                  }
                  if (errorCollectionData) {
                    return <p>{errorCollectionData.message}</p>;
                  }

                  const products = getProductsData(collectionData);

                  return (
                    <Query
                      query={queryVariantData}
                      variables={{
                        id: selectedProductId,
                        selectedOptions: selectedProductOptionsArr
                      }}
                    >
                      {({ loading, error, data: variantData }) => {
                        let selectedVariant = null;

                        if (!loading && !error && variantData) {
                          selectedVariant =
                            variantData.node.variantBySelectedOptions;
                        }

                        return (
                          <DesignCollection
                            products={products}
                            updateURL={updateURL}
                            selectedProductId={selectedProductId}
                            selectedVariant={selectedVariant}
                            variantSelectionPerProduct={
                              variantSelectionPerProduct
                            }
                          />
                        );
                      }}
                    </Query>
                  );
                }}
              </Query>
            </LayoutWrapper>
          );
        }}
      </URLUpdateProvider>
    );
  }
}

export default withRouter(DesignCollectionProvider);

const ProductFragment = gql`
  fragment ProductFragment on Product {
    id
    title
    handle
    descriptionHtml

    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }

    options(first: 3) {
      id
      name
      values
    }

    images(first: 1) {
      edges {
        node {
          id
          src
        }
      }
    }

    variants(first: 10) {
      edges {
        node {
          selectedOptions {
            name
            value
          }
          id
          title
          price
          image {
            altText
            originalSrc
          }
        }
      }
    }
  }
`;

const queryGetCollectionData = gql`
  query getCollectionData($id: ID!) {
    node(id: $id) {
      ... on Collection {
        products(first: 20) {
          edges {
            node {
              ...ProductFragment
            }
          }
        }
      }
    }
  }
  ${ProductFragment}
`;

const queryVariantData = gql`
  query getVariantData($id: ID!, $selectedOptions: [SelectedOptionInput!]!) {
    node(id: $id) {
      id
      ... on Product {
        variantBySelectedOptions(selectedOptions: $selectedOptions) {
          id
          title
          price
          image {
            altText
            originalSrc
          }
        }
      }
    }
  }
`;
