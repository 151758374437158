import styled from "styled-components";

import { Box } from "@/app/components";
import BrickTiling from "@/app/images/brick_tiling.jpg";

export const ImageContainer = styled(Box)`
  overflow: hidden;
  position: relative;
`;

export const Image = styled.img`
  transition: all 0.2s;
  cursor: pointer;

  ${({ animation = true }) => {
    if (animation) {
      return `
      &:hover {
          opacity: 0;
      }`;
    }
  }}
`;

export const ImageWithWall = styled(Box)`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background-position: center center, center center;
  background-size: 60%, cover;
  background-repeat: no-repeat, no-repeat;
  cursor: pointer;

  ${({ path }) => {
    if (path) {
      return `background-image: url(${path}), url(${BrickTiling});`;
    }
  }};
`;
