import { degToRad, lerp } from "canvas-sketch-util/math";
import { random } from "canvas-sketch-util";

const getGrid = ({
  margin = 0,
  countX = 5,
  countY = 5,
  frequency = 1,
  amplitude = 1,
  removalAmount = 0
}) => {
  const points = [];

  for (let i = 0; i < countX; i++) {
    for (let j = 0; j < countY; j++) {
      let u = countX !== 0 ? i / (countX - 1) : 0.5;
      let v = countY !== 0 ? j / (countY - 1) : 0.5;

      u = lerp(margin, 1 - margin, u);
      v = lerp(margin, 1 - margin, v);

      const randomNoise = (random.noise2D(i, j, frequency, amplitude) + 1) / 2;
      const randomValue = random.value();

      const randomValueEpsilon = 0.1; // a bit of a boost to the randomValue so that the below equation can be false;
      const toBeRemoved = randomValue + randomValueEpsilon < removalAmount;

      points.push({
        position: [u, v],
        randomNoise,
        randomValue,
        toBeRemoved
      });
    }
  }

  const scaleX = Math.abs(points[0].position[0] - points[countY].position[0]);
  const scaleY = Math.abs(points[0].position[1] - points[1].position[1]);
  points.forEach(point => {
    point.scale = [scaleX, scaleY];
  });

  return points;
};

const getSphericalGrid = ({
  count = 5,
  minRadius,
  maxRadius,
  removalRatio = 0,
  position,
  frequency = 1,
  amplitude = 1
}) => {
  const points = [];

  const [xPosition, yPosition] = position;
  const iteration = 360 / count;

  for (let i = 0; i < 360; i = i + iteration) {
    const angle = degToRad(i);

    const randomValue = random.value();
    const radius = lerp(minRadius, maxRadius, randomValue);

    const newPosition = [0, 0 + radius];

    const newXPosAfterRot =
      newPosition[1] * Math.sin(angle) + newPosition[0] * Math.cos(angle);
    const newYPosAfterRot =
      newPosition[1] * Math.cos(angle) - newPosition[0] * Math.sin(angle);

    const randomNoise =
      (random.noise2D(newXPosAfterRot, newYPosAfterRot, frequency, amplitude) +
        1) /
      2;

    const internalRandomValue = random.value();
    if (internalRandomValue >= removalRatio) {
      points.push({
        position: [xPosition + newXPosAfterRot, yPosition + newYPosAfterRot],
        randomValue,
        randomNoise
      });
    }
  }

  return points;
};

export { getGrid, getSphericalGrid };
