import React from "react";

import { Box } from "@/app/components";
import DesignWithController from "@/app/components/DesignWithController";

import {
  pureMayor as sketch,
  pureMayorConfig as configuration
} from "@/app/artwork";

export const Playground = () => {
  return (
    <Box m={40}>
      <DesignWithController
        configuration={configuration}
        sketch={sketch}
      ></DesignWithController>
    </Box>
  );
};
