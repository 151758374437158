import React from "react";

import MediaQuery from "react-responsive";

import { Box, Flex, Text } from "@/app/components/Primitives";
import DesignDisplay from "@/app/components/DesignDisplay";
import DesignController from "@/app/components/DesignController";
import { MaxWidthContainer } from "@/app/components/MaxWidthContainer";

import { fluChin } from "@/app/artwork/";

import BrickTiling from "@/app/images/brick_tiling.jpg";

import { getBreakPoint } from "@/app/styles/theme";

import { DesignControllerContainer, HeroContainer } from "./index.components";

export const config = [
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 1906,
    label: "Seed Value"
  },
  {
    name: "count",
    type: "scalar",
    min: 2,
    max: 500,
    defaultValue: 50,
    label: "Count"
  },
  {
    name: "minRadius",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.2,
    label: "Min Outer Radius"
  },
  {
    name: "maxRadius",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.61,
    label: "Max Outer Radius"
  },
  {
    name: "sphereMinRadius",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.106,
    label: "Sphere Min Radius"
  },
  {
    name: "sphereMaxRadius",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.569,
    label: "Sphere Max Radius"
  }
];

export class HeroSection extends React.Component {
  render() {
    const Title = () => (
      <Text fontSize={[3, 3, 3, 2]} fontWeight={"900"} pb={[5, 5, 10]}>
        Create your own procedural art
      </Text>
    );

    const tabletBreakpoint = getBreakPoint("tablet");

    return (
      <MediaQuery minWidth={tabletBreakpoint}>
        {matches => {
          const displaySize = matches ? 600 : "100%";
          const state = this.props.state;

          return (
            <HeroContainer
              px={[10]}
              pb={[10, 10, 20]}
              pt={[10, 20, 20]}
              path={BrickTiling}
            >
              <MaxWidthContainer>
                <Flex flexDirection={["column", "column", "row", "row"]}>
                  {!matches ? <Title /> : null}
                  <Box pb={[10, 10, 0, 0]}>
                    <DesignDisplay
                      state={state}
                      sketch={fluChin}
                      width={displaySize}
                      height={displaySize}
                      aspectRatio={{ x: 1, y: 1 }}
                      extraState={{
                        homeScreen: true,
                        pixelDensity: 1
                      }}
                    />
                  </Box>
                  <Box
                    pl={[0, 0, 20, 20]}
                    width={["auto", "auto", "600px", "600px"]}
                  >
                    {matches ? <Title /> : null}

                    <DesignControllerContainer px={7} py={7} bg="white">
                      <DesignControllerMainPage
                        getState={this.props.getState}
                      />
                    </DesignControllerContainer>
                  </Box>
                </Flex>
              </MaxWidthContainer>
            </HeroContainer>
          );
        }}
      </MediaQuery>
    );
  }
}

const DesignControllerMainPage = ({ getState }) => {
  return (
    <DesignController
      getState={state =>
        getState({
          ...state
        })
      }
      controller={config}
      mainPage={true}
    />
  );
};
