import React from "react";
import queryString from "query-string";
import { withRouter } from "react-router";

// pass expected list of properties and optional maxLen
// returns obj or null
// source: https://stackoverflow.com/questions/25983090/is-sanitizing-json-necessary
// function safeJSONParse({ str, propArray, maxLen }) {
//   if (maxLen && str.length > maxLen) {
//     return null;
//   }

//   try {
//     let safeObj = {};
//     const parsedObj = JSON.parse(str);

//     if (typeof parsedObj === "object" || Array.isArray(parsedObj)) {
//       safeObj = parsedObj;
//     } else {
//       // copy only expected properties to the safeObj
//       propArray.forEach(function(prop) {
//         if (parsedObj.hasOwnProperty(prop)) {
//           safeObj[prop] = parsedObj[prop];
//         }
//       });
//     }

//     return safeObj;
//   } catch (e) {
//     console.log(e);
//     return null;
//   }
// }

const updateURL = (query, history, items) => {
  const parsedQuery = queryString.parse(query);

  items.forEach(item => {
    const [key, value] = item;
    parsedQuery[key] = value;
  });

  const stringQuery = queryString.stringify(parsedQuery);

  history.replace({
    search: `?${stringQuery}`
  });
};

class URLUpdateProvider extends React.Component {
  render() {
    const { children, history, location } = this.props;

    let queryObj = {};
    const stateFromURL = queryString.parse(location.search);

    // @TODO does this need to be sanitized?
    if (stateFromURL) {
      // console.log(">>>>> stateFromUrl", stateFromURL);
      queryObj = stateFromURL;
    }

    return (
      <React.Fragment>
        {children(
          items => updateURL(location.search, history, items),
          queryObj
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(URLUpdateProvider);
