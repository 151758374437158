import styled from "styled-components";
import { themeGet } from "styled-system";

import {
  Box as RebassBox,
  Card,
  Flex as RebassFlex,
  Image,
  Heading,
  Text as TextBase
} from "rebass";

const Box = styled(RebassBox)`
  ${({ height }) => {
    return `height: ${height && height};`;
  }};
`;

const Flex = styled(RebassFlex)`
  ${({ height }) => {
    return `height: ${height && height};`;
  }};
`;

const Text = styled(TextBase)`
  ${({ textTransform }) => {
    return `text-transform: ${textTransform && textTransform};`;
  }};
`;

const BodyText = styled(TextBase)`
  ${({ textTransform, ...props }) => {
    return `
        text-transform: ${textTransform && textTransform};
        color: ${themeGet("colors.gray")(props)};
      `;
  }};
`;

export { BodyText, Box, Card, Flex, Image, Heading, Text };
