import styled from "styled-components";

export const ColorPickerContainer = styled.div`
  position: relative;
`;

export const ColorPickerThumbnailContainer = styled.div`
  padding: 5px;
  background: #fff;

  border-radius: 1px;
  boxshadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;

export const ColorPickerThumbnail = styled.div`
  border: 1px solid #ddd;
  width: 36px;
  height: 14px;
  border-radius: 2px;
  ${({ color }) => {
    return `background: ${color}`;
  }};
`;

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`;
