import React from "react";
import styled from "styled-components";

import ColorPicker from "../ColorPicker";

const ColorPaletteContainer = styled.div`
  display: flex;
`;

class ColorPalette extends React.Component {
  handleChange = (color, index) => {
    const { colors, onChange } = this.props;
    const newColors = [...colors];
    newColors[index] = color;

    onChange(newColors);
  };

  render() {
    const { colors } = this.props;
    const colorPickers = colors.map((color, index) => {
      return (
        <ColorPicker
          key={index}
          color={color}
          onChange={color => this.handleChange(color, index)}
        />
      );
    });

    return <ColorPaletteContainer>{colorPickers}</ColorPaletteContainer>;
  }
}

export default ColorPalette;
