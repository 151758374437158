import styled from "styled-components";

export const ControllerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SlidersContainer = styled.div`
  ${props => {
    if (props.mainPage) {
      return ``;
    }
    return `
      overflow: auto;
      max-height: 100vh;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 20px;
      padding-bottom: 20px;
      box-shadow: 2px 2px 10px 2px #cec7c7; // TODO: USE THEME COLOR HERE
    `;
  }}
`;

export const SliderContainer = styled.div`
  margin-bottom: 16px;
`;

export const SliderLabel = styled.div`
  // font-family: Roboto;
  font-size: 14px;
  margin-bottom: 8px;
`;
