import React, { Component } from "react";
import { graphql, compose } from "react-apollo";
import { Helmet } from "react-helmet";
import gql from "graphql-tag";

import { checkoutCustomerAssociate } from "../../checkout";

import Cart from "../Cart";
import CustomerAuthentication from "../CustomerAuthentication";
import Header from "../Header";
import Footer from "../Footer";
import NewsLetter from "../Newsletter";
import LoadingIcon from "@/app/components/LoadingIcon";

import { ChildrenContainer } from "./index.components";
import OgImage from "@/app/images/ogimage.png";

class Layout extends Component {
  state = {
    isCartOpen: false,
    isCustomerAuthOpen: false,
    isNewCustomer: false
  };

  handleCartOpen = () => {
    this.setState({
      isCartOpen: true
    });
  };

  handleCartClose = () => {
    this.setState({
      isCartOpen: false
    });
  };

  handleOpenCheckout = () => {
    window.open(this.props.checkout.webUrl);
  };

  openCustomerAuth = event => {
    if (event.target.getAttribute("data-customer-type") === "new-customer") {
      this.setState({
        isNewCustomer: true,
        isCustomerAuthOpen: true
      });
    } else {
      this.setState({
        isNewCustomer: false,
        isCustomerAuthOpen: true
      });
    }
  };

  closeCustomerAuth = () => {
    this.setState({
      isCustomerAuthOpen: false
    });
  };

  render = () => {
    const {
      checkout,
      children,
      data,
      displayNewsLetter = false,
      removeLineItemInCart,
      updateLineItemInCart,
      title
    } = this.props;

    if (data.loading) {
      return <LoadingIcon />;
    }

    if (data.error) {
      return <p>{data.error.message}</p>;
    }

    const cartHasItems = checkout.lineItems.edges.length > 0;

    const siteTitle = `${
      title ? `ProceduralArt.com | ${title}` : "ProceduralArt.com"
    }`;
    const siteUrl = "https://www.proceduralart.com";
    const siteDescription =
      "Design a unique, personalized, procedural art and print it on your favourite things. We deliver around the world.";
    const siteName = "ProceduralArt";

    return (
      <div className="App">
        <Helmet>
          <meta name="description" content={siteDescription} />
          <meta
            name="robots"
            content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link rel="canonical" href={siteUrl} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={siteTitle} />
          <meta property="og:description" content={siteDescription} />
          <meta property="og:url" content={siteUrl} />
          <meta property="og:site_name" content={siteName} />
          <meta property="og:image" content={OgImage} />
          <meta property="og:image:secure_url" content={OgImage} />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="900" />
          <meta name="twitter:card" content={OgImage} />
          <meta name="twitter:description" content={siteDescription} />
          <meta name="twitter:title" content={siteTitle} />
          <meta name="twitter:image" content={OgImage} />
          <meta
            itemprop="keywords"
            content="proceduralart,generativeart,p5js,art,print,posters,javascript"
          />
          <meta
            name="keywords"
            content="Procedural Art, Art, Print, Posters, Generative Art, p5js, JavaScript"
          />
        </Helmet>
        <CustomerAuthentication
          closeCustomerAuth={this.closeCustomerAuth}
          isCustomerAuthOpen={this.state.isCustomerAuthOpen}
          isNewCustomer={this.state.isNewCustomer}
        />
        <Header
          name={data.shop.name}
          description={data.shop.description}
          isCartOpen={this.state.isCartOpen}
          onCartOpen={() => {
            this.setState({ isCartOpen: true });
          }}
          onOpenCustomerAuth={this.openCustomerAuth}
          cartHasItems={cartHasItems}
        />
        <Cart
          removeLineItemInCart={removeLineItemInCart}
          updateLineItemInCart={updateLineItemInCart}
          checkout={checkout}
          isCartOpen={this.state.isCartOpen}
          handleCartClose={this.handleCartClose}
          customerAccessToken={this.state.customerAccessToken}
          handleOpenCheckout={this.handleOpenCheckout}
        />
        <ChildrenContainer>
          {children({
            handleCartOpen: this.handleCartOpen,
            handleOpenCheckout: this.handleOpenCheckout
          })}
        </ChildrenContainer>
        {displayNewsLetter && <NewsLetter />}
        <Footer />
      </div>
    );
  };
}

const query = gql`
  query query {
    shop {
      name
      description
    }
  }
`;

const LayoutWithDataAndMutation = compose(
  graphql(query),
  graphql(checkoutCustomerAssociate, { name: "checkoutCustomerAssociate" })
)(Layout);

export default LayoutWithDataAndMutation;
