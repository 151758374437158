import { random } from "canvas-sketch-util";
import colors from "nice-color-palettes";

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined
} from "../../utilities/misc";
import { getGrid } from "../../utilities/grid";

export const config = [
  {
    name: "seedValue",
    type: "scalar",
    min: 0,
    max: 9999,
    defaultValue: 0,
    label: "Seed Value"
  },
  {
    name: "margin",
    type: "float",
    min: 0,
    max: 0.4,
    defaultValue: 0,
    label: "Margin"
  },
  {
    name: "count",
    type: "scalar",
    min: 2,
    max: 100,
    defaultValue: 24,
    label: "Count"
  },
  {
    name: "removalAmount",
    type: "float",
    min: 0,
    max: 1,
    defaultValue: 0.494,
    label: "Sphere Removal Ratio"
  },
  {
    name: "stroke",
    type: "boolean",
    defaultValue: false,
    label: "Has Stroke?"
  },
  {
    name: "colorTheme",
    type: "scalar",
    min: 0,
    max: 99,
    defaultValue: 42,
    label: "Color Theme"
  },
  {
    name: "colorAmount",
    type: "scalar",
    min: 1,
    max: 5,
    defaultValue: 3,
    label: "Color Amount"
  },
  {
    name: "bgColor",
    type: "color",
    defaultValue: "#ffffff",
    label: "Background Color"
  }
];

const sketch = p => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let seedValue = getConfigValueIfUndefined(
    config,
    configFromState,
    "seedValue"
  );
  let margin = getConfigValueIfUndefined(config, configFromState, "margin");
  let count = getConfigValueIfUndefined(config, configFromState, "count");
  let removalAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "removalAmount"
  );
  let stroke = getConfigValueIfUndefined(config, configFromState, "stroke");

  let colorTheme = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorTheme"
  );
  let colorAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "colorAmount"
  );
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");

  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = props => {
    if (exists(props.seedValue)) {
      seedValue = parseInt(props.seedValue, 10);
    }

    if (exists(props.margin)) {
      margin = parseFloat(props.margin);
    }

    if (exists(props.count)) {
      count = parseInt(props.count, 10);
    }

    if (exists(props.removalAmount)) {
      removalAmount = parseFloat(props.removalAmount);
    }

    if (exists(props.colorAmount)) {
      colorAmount = parseInt(props.colorAmount, 10);
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }

    if (exists(props.stroke)) {
      stroke = props.stroke;
    }

    if (exists(props.colorTheme)) {
      colorTheme = parseInt(props.colorTheme, 10);
    }
  };

  p.setup = () => {
    p.createCanvas(1024, 1024);
    p.pixelDensity(pixelDensity);
    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(6);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    random.setSeed(seedValue);
    p.background(bgColor);

    const width = p.width;
    const height = p.height;

    const points = getGrid({
      countX: count,
      countY: count,
      margin,
      removalAmount
    });

    points.forEach(item => {
      const { position, scale, toBeRemoved } = item;

      const [u, v] = position;
      const [scaleX, scaleY] = scale;

      let x = u * width;
      let y = v * height;

      const randomColorRect = random.pick(
        colors[colorTheme].slice(0, colorAmount)
      );
      const randomColorSphere = random.pick(
        colors[colorTheme].slice(0, colorAmount)
      );

      const rectSizeX = scaleX * width;
      const rectSizeY = scaleY * height;

      if (stroke) {
        p.stroke("black");
      } else {
        p.stroke(randomColorRect);
      }

      p.fill(randomColorRect);
      p.rect(x, y, rectSizeX, rectSizeY);

      if (toBeRemoved) {
        return;
      }

      p.fill(randomColorSphere);
      p.ellipse(x, y, rectSizeX * 0.5, rectSizeY * 0.5);
    });
  };
};

export default sketch;
