import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";

import LoadingIcon from "@/app/components/LoadingIcon";
import { Gallery } from "./Gallery";

export const GalleryProvider = () => {
  return (
    <Query query={query}>
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingIcon />;
        }
        if (error) {
          return <p>{error.message}</p>;
        }

        const collectionsData = data.shop.collections;

        return <Gallery collections={collectionsData} />;
      }}
    </Query>
  );
};

const query = gql`
  query query {
    shop {
      collections(first: 50, sortKey: UPDATED_AT) {
        edges {
          node {
            id
            title
            image {
              originalSrc
            }
            products(first: 10) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
