import React from "react";
import { ChromePicker } from "react-color";
import {
  ColorPickerContainer,
  ColorPickerThumbnail,
  ColorPickerThumbnailContainer,
  Cover,
  PopOver
} from "./index.components";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      color: props.color || "#ff0000"
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = color => {
    const { onChange } = this.props;
    const hexColor = color.hex;

    this.setState({ color: hexColor }, () => {
      if (onChange) {
        onChange(hexColor);
      }
    });
  };

  render() {
    return (
      <ColorPickerContainer>
        <ColorPickerThumbnailContainer onClick={this.handleClick}>
          <ColorPickerThumbnail color={this.state.color} />
        </ColorPickerThumbnailContainer>
        {this.state.displayColorPicker ? (
          <PopOver>
            <Cover onClick={this.handleClose} />
            <ChromePicker
              disableAlpha
              color={this.state.color}
              onChange={this.handleChange}
            />
          </PopOver>
        ) : null}
      </ColorPickerContainer>
    );
  }
}

export default ColorPicker;
