import { lerp } from "canvas-sketch-util/math";

import {
  exists,
  getConfigValueIfUndefined,
  getValueIfUndefined
} from "../../utilities/misc";
import tinyColor from "../../utilities/tinycolor";

export const config = [
  {
    name: "text",
    type: "text",
    defaultValue: "COOL",
    label: "Text"
  },
  {
    name: "textSize",
    type: "float",
    min: 50,
    max: 400,
    defaultValue: 235,
    label: "Text Size"
  },
  {
    name: "textPositionOffset",
    type: "float",
    min: -400,
    max: 400,
    defaultValue: -100,
    label: "Text Position Offset"
  },
  {
    name: "spacing",
    type: "scalar",
    min: 0,
    max: 100,
    defaultValue: 76,
    label: "Spacing"
  },
  {
    name: "spacingMultiplier",
    type: "float",
    min: 0,
    max: 10,
    defaultValue: 0.269,
    label: "Spacing Multiplier"
  },
  {
    name: "textCopyAmount",
    type: "scalar",
    min: 0,
    max: 80,
    defaultValue: 40,
    label: "Text Copy Amount"
  },
  {
    name: "strokeWeight",
    type: "float",
    min: 0,
    max: 40,
    defaultValue: 20,
    label: "Stroke Weight"
  },
  {
    name: "strokeColor",
    type: "color",
    defaultValue: "#142149",
    label: "Stroke Color"
  },
  {
    name: "textColor",
    type: "color",
    defaultValue: "#bba3dc",
    label: "Text Color"
  },

  {
    name: "bgColor",
    type: "color",
    defaultValue: "#b9d0db",
    label: "Background Color"
  },
  {
    name: "isTransparent",
    type: "boolean",
    defaultValue: false,
    label: "Is Transparent"
  }
];

const sketch = (p, z) => {
  const givenState = document.p5jsState;
  const state = givenState || {};
  const configFromState = state.configuration;

  let text = getConfigValueIfUndefined(config, configFromState, "text");
  let textSize = getConfigValueIfUndefined(config, configFromState, "textSize");
  let textPositionOffset = getConfigValueIfUndefined(
    config,
    configFromState,
    "textPositionOffset"
  );
  let spacing = getConfigValueIfUndefined(config, configFromState, "spacing");
  let spacingMultiplier = getConfigValueIfUndefined(
    config,
    configFromState,
    "spacingMultiplier"
  );
  let textCopyAmount = getConfigValueIfUndefined(
    config,
    configFromState,
    "textCopyAmount"
  );
  let strokeWeight = getConfigValueIfUndefined(
    config,
    configFromState,
    "strokeWeight"
  );
  let textColor = getConfigValueIfUndefined(
    config,
    configFromState,
    "textColor"
  );
  let strokeColor = getConfigValueIfUndefined(
    config,
    configFromState,
    "strokeColor"
  );
  let bgColor = getConfigValueIfUndefined(config, configFromState, "bgColor");
  let isTransparent = getConfigValueIfUndefined(
    config,
    configFromState,
    "isTransparent"
  );

  let screenSizeX = getValueIfUndefined(state, "screenSizeX", 1024);
  let screenSizeY = getValueIfUndefined(state, "screenSizeY", 1024);
  let pixelDensity = getValueIfUndefined(state, "pixelDensity", 0.5);

  p.myCustomRedrawAccordingToNewPropsHandler = props => {
    if (exists(props.text)) {
      text = props.text;
    }

    if (exists(props.textSize)) {
      textSize = parseFloat(props.textSize);
    }

    if (exists(props.textPositionOffset)) {
      textPositionOffset = parseFloat(props.textPositionOffset);
    }

    if (exists(props.spacing)) {
      spacing = parseInt(props.spacing, 10);
    }

    if (exists(props.spacingMultiplier)) {
      spacingMultiplier = parseFloat(props.spacingMultiplier);
    }

    if (exists(props.textCopyAmount)) {
      textCopyAmount = parseInt(props.textCopyAmount, 10);
    }

    if (exists(props.strokeWeight)) {
      strokeWeight = parseFloat(props.strokeWeight);
    }

    if (exists(props.textColor)) {
      textColor = props.textColor;
    }

    if (exists(props.strokeColor)) {
      strokeColor = props.strokeColor;
    }

    if (exists(props.bgColor)) {
      bgColor = props.bgColor;
    }

    if (exists(props.isTransparent)) {
      isTransparent = props.isTransparent;
    }
  };

  p.setup = () => {
    p.createCanvas(screenSizeX, screenSizeY);
    p.pixelDensity(pixelDensity);

    p.rectMode(p.CENTER);
    p.angleMode(p.DEGREES);
    p.frameRate(6);
    p.textAlign(p.CENTER, p.CENTER);
  };

  p.draw = () => {
    p.background(bgColor);

    const width = p.width;
    const height = p.height;
    const originX = width / 2;
    const originY = height / 2;

    for (let i = spacing * textCopyAmount; i > 0; i -= spacing) {
      let finalTextColor = textColor;
      let finalStrokeColor = strokeColor;

      if (isTransparent) {
        const gradient = lerp(0, 1, spacing / i);

        finalTextColor = tinyColor(textColor)
          .setAlpha(gradient)
          .toRgbString();

        finalStrokeColor = tinyColor(strokeColor)
          .setAlpha(gradient)
          .toRgbString();
      }

      p.push();
      p.translate(0, i * spacingMultiplier);
      // styling
      p.fill(finalTextColor);
      p.strokeWeight(strokeWeight);
      p.stroke(finalStrokeColor);
      p.textSize(textSize);
      p.textFont("Helvetica");
      // styling ends
      p.text(text, originX, originY + textPositionOffset);
      p.pop();
    }
  };
};

export default sketch;
