import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { X } from "react-feather";

import { Box, Flex, Text } from "@/app/components";
import { border } from "@/app/styles/mixins";
import theme from "@/app/styles/theme";

export const CartContainer = styled(Box)`
  ${({ isOpen }) => {
    const translateValue = isOpen ? `translateX(0)` : `translateX(100%)`;

    return `
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 350px;
        background-color: white;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #e5e5e5;
        transform: ${translateValue};
        transition: transform 0.15s ease-in-out;
        z-index: 99;
    `;
  }}
`;

const CartPriceItem = ({ label, value }) => {
  return (
    <Box pb={2}>
      <Flex justifyContent="space-between">
        <Text fontSize={8} textTransform="uppercase">
          {label}
        </Text>
        <Text fontSize={8} textTransform="uppercase" fontWeight={"600"}>
          $ {value}
        </Text>
      </Flex>
    </Box>
  );
};

const CartFooterContainer = styled.footer`
  border-top: ${border()};
`;

export const CartFooter = ({ children, subtotal, tax, total }) => {
  return (
    <CartFooterContainer>
      <Box p={5}>
        <Box pb={2}>
          <CartPriceItem label="Subtotal" value={subtotal} />
          <CartPriceItem label="Taxes" value={tax} />
          <CartPriceItem label="Total" value={total} />
        </Box>
        <Box>{children}</Box>
      </Box>
    </CartFooterContainer>
  );
};

const CartHeaderContainer = styled.header`
  ${props => {
    return `
        padding: ${themeGet("space.5")(props)}px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: ${border()}
    `;
  }}
`;

const CartCloseButton = styled.button`
  border: none;
  background: none;
  line-height: 1;
`;

export const CartHeader = ({ handleCartClose }) => {
  return (
    <CartHeaderContainer>
      <h2>Cart</h2>
      <CartCloseButton onClick={handleCartClose}>
        <X size={"20px"} stroke={theme.colors.darkGray} />
      </CartCloseButton>
    </CartHeaderContainer>
  );
};

export const CartLineItems = styled.ul`
  ${props => {
    return `
        padding: ${themeGet("space.5")(props)}px;
    `;
  }}
`;

export const EmptyCartMessage = (
  <Box p={5}>
    <Text fontSize={7}>Your cart is currently empty.</Text>
  </Box>
);
