import React, { Component } from "react";

import {
  CartContainer,
  CartFooter,
  CartHeader,
  CartLineItems,
  EmptyCartMessage
} from "./Cart.components";
import { MainButton } from "@/app/components/Button";
import LineItem from "../LineItem";

class Cart extends Component {
  render() {
    const {
      lineItems: _lineItems,
      subtotalPrice,
      totalTax,
      totalPrice
    } = this.props.checkout;

    const lineItems = _lineItems.edges.map(lineItem => {
      return (
        <LineItem
          removeLineItemInCart={this.props.removeLineItemInCart}
          updateLineItemInCart={this.props.updateLineItemInCart}
          key={lineItem.node.id.toString()}
          line_item={lineItem.node}
        />
      );
    });

    const isCartEmpty = lineItems.length === 0;
    const cartContent = isCartEmpty ? (
      EmptyCartMessage
    ) : (
      <React.Fragment>
        <CartLineItems>{lineItems}</CartLineItems>
        <CartFooter subtotal={subtotalPrice} tax={totalTax} total={totalPrice}>
          <MainButton onClick={this.props.handleOpenCheckout} fullWidth>
            Checkout
          </MainButton>
        </CartFooter>
      </React.Fragment>
    );

    return (
      <CartContainer isOpen={this.props.isCartOpen}>
        <CartHeader handleCartClose={this.props.handleCartClose} />
        {cartContent}
      </CartContainer>
    );
  }
}

export default Cart;
